import React from 'react'
import { useNavigate } from 'react-router-dom';
import logo from '../static/dev_logo.png';
import gameScreenshot1 from '../static/final_void/FV_screenshot_1.jpg'
import gameScreenshot2 from '../static/final_void/FV_screenshot_2.jpg'
import gameScreenshot3 from '../static/final_void/FV_screenshot_3.jpg'
import TesterSignUpModal from './BecomeTester';
import { Banner } from 'exoclick-react';

import "../App.css"

const HomePage = () => {

const navigate = useNavigate()

  return (
    <div className='logoContainer'>
        <img src={logo} className='App-logo' alt='Dev-Logo'/>
      <div className='AboutSection'>
        <h1 className='AboutUs'>HELLO THERE</h1>
        <p>At Dissonant Druid, we have fun making games and hope you have fun playing them! 
            You can find all our games under the 'Games' option in the dropdown menu.</p>

      </div>
      <div className='FeaturedSection'>
        <h1 className='FeaturedTitle'>Featured Game</h1>
        <p className='FeaturedDescription'>Final Void is a dark 2D platformer where you control a powerful wizard on a quest through <br />
                                           eerie landscapes. Battle eldritch monsters and seek out ancient relics in challenging levels filled <br />
                                           with sinister creatures. This 3-level demo offers a glimpse of the full game, with its Lovecraftian <br />
                                           atmosphere and relentless difficulty. Can you conquer the void? Embark on this adventure and <br />
                                           prove your mastery of the arcane!</p>
        <div className='Screenshots'>
          <img src={gameScreenshot1} alt='Screenshot 1' className='Screenshot' />
          <img src={gameScreenshot2} alt='Screenshot 2' className='Screenshot' />
          <img src={gameScreenshot3} alt='Screenshot 3' className='Screenshot' />
        </div>



        <div className='homePageButtons'>
        <a href="https://play.google.com/store/apps/details?id=com.finalvoid.mobile&pcampaignid=web_share" target="_blank" rel="noopener noreferrer" className='PlayStoreLink'>
          <button className='PlayStoreButton'>Get it on Google Play</button>
        </a>
        <button 
          className='PlayOnlineButton'
          onClick={() => navigate('/final-void-online')}>
            Play it Online!
        </button>
        </div>

      </div>
      <TesterSignUpModal />

      {/*<Banner zoneId={5443852} />
      
      <Banner zoneId={5445298} /> */}
    </div>
  )
}

export default HomePage