import { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import './Components/DataDeletePage'
import { db, ref, update, set, increment, database } from './firebaseConfig.js';
import DataDeletePage from './Components/DataDeletePage';
import HomePage from './Components/HomePage';
import GamesPage from './Components/GamesPage';
import AboutPage from './Components/AboutPage';
import ContactPage from './Components/ContactPage';
import BecomeTester from './Components/BecomeTester'
import NavbarMenu from './Components/NavbarMenu.js';
import BlogPage from './Components/BlogPage';
import Footer from './Components/Footer';
import BlogPost from './Components/BlogPost';
import GameSelection from './Components/GameSelection';
import DesktopGame from './Components/DesktopGame';
import NeonHorizonWeb from './Components/NeonHorizonWeb.js';
import ArrowDash from './Components/ArrowDash.js';
import { Banner } from "exoclick-react";
import * as QuizData from './Components/QuizData.js'
import QuizList from './Components/QuizList.js';
import Quiz from './Components/Quiz.js';
import PaddleWar from './Components/Pong.js'
import SpaceBreaker from './Components/SpaceBreaker.js';
import EndlessSpacePilot from './Components/EndlessSpacePilot.js';
import { HelmetProvider } from 'react-helmet-async';
import AdCashAntiAdblock from './Components/AdCashAntiAdblock.js';




function App() {

  useEffect(() => {
    const counterRef = ref(database, 'visitorCount'); // Reference 'visitorCount' in Realtime Database
    
    // Use `update()` to increment the visitor count without reading
    update(counterRef, {
      visitorCount: increment(1) // Directly increment by 1
    }).catch((error) => {
      console.error("Error updating visitor count:", error);
    });
  }, []);


  return (
    <HelmetProvider>
    <Router>
      <div className="App">
        <AdCashAntiAdblock />
        <nav className='nav-bar' style={{
                                    position: 'fixed',
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    alignItems: 'center',
                                    padding: '10px',
                                    backgroundColor: 'black',
                                    color: 'white',
                                  }}>
                                    <NavbarMenu />
            <ul>
                <li>
                </li>
             </ul>
          <div>
            <h1 className='devName'>ＤＩＳＳＯＮＡＮＴ ＤＲＵＩＤ</h1>
          </div>
        </nav>
        
      

          <Routes>
            <Route index element={<HomePage />} />
            <Route path="/privacy-notice" element={<DataDeletePage />} />
            <Route path="/games" element={<GamesPage />} />
            <Route path="/about" element={<AboutPage />} />
            <Route path="/contact" element={<ContactPage />} />
            <Route path='/betester' element={<BecomeTester />} />
            <Route path='/blog-updates' element={<BlogPage />} />
            <Route path='/blog-updates/:postId' element={<BlogPost />} />
            <Route path='/final-void-online' element={<GameSelection />} />
            <Route path="/desktop-game" element={<DesktopGame />} />
            <Route path='/neon-horizon' element={<NeonHorizonWeb />} />
            <Route path='/arrow-dash' element={<ArrowDash />} />
            <Route path='/paddle-war' element={<PaddleWar />} />
            <Route path='/space-breaker' element={<SpaceBreaker />} />
            <Route path='/endless-space-pilot' element={<EndlessSpacePilot />} />
            <Route path="/quiz/silenthill/:questionIndex" element={<Quiz quizData={QuizData.silentHill2RemakeQuiz} />} />
            <Route path="/quiz/minecraft/:questionIndex" element={<Quiz quizData={QuizData.minecraftQuiz} />} />
            <Route path="/quiz/gaminghistory/:questionIndex" element={<Quiz quizData={QuizData.gamingHistoryQuiz} />} />
            <Route path="/quiz/playstationhistory/:questionIndex" element={<Quiz quizData={QuizData.playstationHistoryQuiz} />} />
            <Route path="/quiz/matchgameswithdevelopers/:questionIndex" element={<Quiz quizData={QuizData.matchGameWithDeveloperQuiz} />} />
            <Route path="/quiz/skyrim/:questionIndex" element={<Quiz quizData={QuizData.skyrimQuiz} />} />
            <Route path="/quiz/halo/:questionIndex" element={<Quiz quizData={QuizData.haloQuiz} />} />
            <Route path="/quiz/mariohistory/:questionIndex" element={<Quiz quizData={QuizData.marioHistoryQuiz} />} />
            <Route path="/quiz/ocarinaoftime/:questionIndex" element={<Quiz quizData={QuizData.ocarinaOfTimeQuiz} />} />
            <Route path="/quiz/streetfighter/:questionIndex" element={<Quiz quizData={QuizData.streetFighterQuiz} />} />
            <Route path="/quiz/mortalkombat/:questionIndex" element={<Quiz quizData={QuizData.mortalKombatQuiz} />} />
            <Route path="/quiz/gamingtrivia/:questionIndex" element={<Quiz quizData={QuizData.gamingTriviasQuiz} />} />
            <Route path="/quiz/witcher/:questionIndex" element={<Quiz quizData={QuizData.witcherQuiz} />} />
            <Route path="/quiz/csgo/:questionIndex" element={<Quiz quizData={QuizData.csgoQuiz} />} />
            <Route path="/quiz/residentevil4/:questionIndex" element={<Quiz quizData={QuizData.re4Quiz} />} />
            <Route path="/quiz/fortnite/:questionIndex" element={<Quiz quizData={QuizData.fortniteQuiz} />} />
            <Route path='/quiz-list' element={<QuizList />} />
          </Routes>
      </div>



    <Footer />
    </Router>
    </HelmetProvider>
  );
}

export default App;
