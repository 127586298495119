import React from 'react';
import { Link } from 'react-router-dom';
import * as QuizData from '../Components/QuizData'; // Adjust the path if necessary
import '../QuizList.css'
import { Helmet } from 'react-helmet';
import OutstreamVideoAd from './OutstreamVideoAd';
import path from 'path-browserify';

const QuizList = () => {
    const quizzes = [
        {
            name: "Silent Hill 2 Quiz",
            path: QuizData.silentHill2RemakeQuiz.quizPath,
            header: QuizData.silentHill2RemakeQuiz.quizHeader,
        },
        {
            name: "Minecraft Quiz",
            path: QuizData.minecraftQuiz.quizPath,
            header: QuizData.minecraftQuiz.quizHeader,
        },
        {
            name: "Gaming History Quiz",
            path: QuizData.gamingHistoryQuiz.quizPath,
            header: QuizData.gamingHistoryQuiz.quizHeader,
        },
        {
            name: "History of Playstation Quiz",
            path: QuizData.playstationHistoryQuiz.quizPath,
            header: QuizData.playstationHistoryQuiz.quizHeader,
        },
        {
            name: "Can You Matche These Games With Their Developers?",
            path: QuizData.matchGameWithDeveloperQuiz.quizPath,
            header: QuizData.matchGameWithDeveloperQuiz.quizHeader,
        },
        {
            name: "Think you're the Dragonborn? Test your knowledge about Skyrim and its epic lore, quests, and characters!",
            path: QuizData.skyrimQuiz.quizPath,
            header: QuizData.skyrimQuiz.quizHeader,
        },
        {
            name: "Are you a true Spartan? Test your knowledge about the Halo universe and its epic battles, characters, and lore!",
            path: QuizData.haloQuiz.quizPath,
            header: QuizData.haloQuiz.quizPath,
        },
        {
            name: "Think you know everything about Mario? Test your knowledge about the history of Mario games, from the classic platformers to the modern adventures!",
            path: QuizData.marioHistoryQuiz.quizPath,
            header: QuizData.marioHistoryQuiz.quizHeader,
        },
        {
            name: "Think you're a true Hero of Time? Test your knowledge about 'The Legend of Zelda: Ocarina of Time' with this challenging quiz!",
            path: QuizData.ocarinaOfTimeQuiz.quizPath,
            header: QuizData.ocarinaOfTimeQuiz.quizHeader,
        },
        {
            name: "Are you a true World Warrior? Test your knowledge about the Street Fighter series and its legendary characters, moves, and history!",
            path: QuizData.streetFighterQuiz.quizPath,
            header: QuizData.streetFighterQuiz.quizHeader,
        },
        {
            name: "Do you have what it takes to survive Mortal Kombat? Test your knowledge about the iconic fighters, fatalities, and the bloody history of the Mortal Kombat series!",
            path: QuizData.mortalKombatQuiz.quizPath,
            header: QuizData.mortalKombatQuiz.quizHeader,
        },
        {
            name: "How well do you know the world of video games? From iconic characters to groundbreaking moments, this gaming trivia quiz will challenge your overall knowledge of the gaming universe!",
            path: QuizData.gamingTriviasQuiz.quizPath,
            header: QuizData.gamingTriviasQuiz.quizHeader,
        },
        {
            name: "Think you know everything about Geralt of Rivia and the world of The Witcher? Test your knowledge about the story, characters, and lore of the iconic Witcher series!",
            path: QuizData.witcherQuiz.quizPath,
            header: QuizData.witcherQuiz.quizHeader,
        },
        {
            name: "Think you know everything about Counter-Strike: Global Offensive? Test your knowledge about the weapons, maps, and strategies of this iconic shooter!",
            path: QuizData.csgoQuiz.quizPath,
            header: QuizData.csgoQuiz.quizHeader,
        },
        {
            name: "Think you know everything about Resident Evil 4? Test your knowledge about Leon's journey, iconic enemies, and memorable moments in this survival horror classic!",
            path: QuizData.re4Quiz.quizPath,
            header: QuizData.re4Quiz.quizPath,
        },
        {
            name: "Are you a pro or noob? Test your knowledge of Fortnite!",
            path: QuizData.fortniteQuiz.quizPath,
            header: QuizData.fortniteQuiz.quizHeader,
        }
        // Add more quizzes here as needed
    ];

    return (
        <div className="quiz-list-container">
            <Helmet>
                <title>Test Your Knowledge With Our Gaming Quizzes!</title>
                <meta property="og:title" content="Test Your Knowledge With Our Gaming Quizzes!" />
                <meta property="og:description" content="Explore our collection of fun and challenging gaming quizzes to test your knowledge across a variety of games!" />
            </Helmet>
            <h1>Test Your Game Knowledge With Our Gaming Quizzes</h1>
            <ul className="quiz-list">
            {quizzes
                .sort((a, b) => a.name.localeCompare(b.name)) // Sort the quizzes alphabetically by name
                .map((quiz, index) => (
                    <React.Fragment key={index}>
                        <li className="quiz-item">
                            <Link to={`/quiz/${quiz.path}/0`} className="quiz-link">
                                <h2>{quiz.name}</h2>
                                <p>{quiz.header}</p>
                            </Link>
                        </li>
                        {(index + 1) % 2 === 0 && (
                            <li className="ad-item">
                    {/* Insert your ad component here */}
                               {/* <OutstreamVideoAd /> */}
                            </li>
                        )}
                    </React.Fragment>
                ))}

            </ul>
        </div>
    );
    
};

export default QuizList;
