import React, { useState, useRef } from 'react'
import '../SpaceBreaker.css'
import BuyMeCoffee from './BuyMeCoffee';
import { Helmet } from 'react-helmet';
import OutstreamVideoAd from './OutstreamVideoAd';

const SpaceBreaker = () => {
    const [isFullScreen, setIsFullScreen] = useState(false); // Track full-screen state
    const iframeRef = useRef(null); // Reference to the iframe
  
    // Function to handle full-screen toggle
    const toggleFullScreen = () => {
      if (!isFullScreen) {
        // Enter full-screen mode
        if (iframeRef.current) {
          iframeRef.current.requestFullscreen()
            .then(() => setIsFullScreen(true))
            .catch(err => console.error("Failed to enter full-screen mode:", err));
        }
      } else {
        // Exit full-screen mode
        document.exitFullscreen()
          .then(() => setIsFullScreen(false))
          .catch(err => console.error("Failed to exit full-screen mode:", err));
      }
    };
  
    return (
      <div className='SpaceBreakerContainer'>

            <Helmet>
                <title>Space Breaker - A Classic Arcade-Inspired Space Shooter!</title>
                <meta property="og:title" content="Space Breaker - A Classic Arcade-Inspired Space Shooter!" />
                <meta 
                    property="og:description" 
                    content="Blast through waves of asteroids and defend your ship in Space Breaker, an exciting space shooter inspired by the classic game Asteroids!" 
                />
                <meta property="og:image" content="/images/games/space-breaker.png" />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://dissonantdruid.com/space-breaker" />
            </Helmet>

        {/* Iframe for the game */}
        <iframe
          ref={iframeRef}
          src="/asteroids/index.html"
          title="Desktop Game"
          className='SpaceBreakerWindow'
        />
    
    <div className='GameTitle'>SPACE BREAKER</div>

        {/* Full-Screen Toggle Button*/}
        <button onClick={toggleFullScreen} className='FullScreenButton'>
          {isFullScreen ? 'Exit Full Screen' : 'Full Screen'}
        </button>

        <div className='BuyCoffee'>
          <div className='CoffeeText'>
            Enjoying the game? Buy us a coffee to show your support!
          </div>
          <BuyMeCoffee />
        </div>
       {/* <OutstreamVideoAd /> */}
      </div>
    )
}

export default SpaceBreaker