import React, { useState, useRef } from 'react'
import '../NeonHorizon.css'
import BuyMeCoffee from './BuyMeCoffee.js';
import { Helmet } from 'react-helmet-async';
import OutstreamVideoAd from './OutstreamVideoAd.js';


const NeonHorizonWeb = () => {
    const [isFullScreen, setIsFullScreen] = useState(false); // Track full-screen state
    const iframeRef = useRef(null); // Reference to the iframe
  
    // Function to handle full-screen toggle
    const toggleFullScreen = () => {
      if (!isFullScreen) {
        // Enter full-screen mode
        if (iframeRef.current) {
          iframeRef.current.requestFullscreen()
            .then(() => setIsFullScreen(true))
            .catch(err => console.error("Failed to enter full-screen mode:", err));
        }
      } else {
        // Exit full-screen mode
        document.exitFullscreen()
          .then(() => setIsFullScreen(false))
          .catch(err => console.error("Failed to exit full-screen mode:", err));
      }
    };
  
    return (
      <div className='NeonHorizonContainer'>
            <Helmet>
                <title>Neon Horizon - A Synthwave Endless Runner</title>
                <meta property="og:title" content="Neon Horizon - A Synthwave Endless Runner" />
                <meta 
                    property="og:description" 
                    content="Dive into Neon Horizon, an endless runner drenched in synthwave vibes. Dodge obstacles in a neon-lit world and race toward the horizon!" 
                />
                <meta property="og:image" content="/images/games/neon-horizon.png" />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://dissonantdruid.com/neon-horizon" />
            </Helmet>
        {/* Iframe for the game */}
        <iframe
          ref={iframeRef}
          src="/neon-horizon-web/NH_web_desktop.html"
          title="Desktop Game"
          className='NeonHorizonWindow'
        />
    
    <div className='GameTitle'>NEON HORIZON</div>

        {/* Full-Screen Toggle Button*/}
        <button onClick={toggleFullScreen} className='FullScreenButton'>
          {isFullScreen ? 'Exit Full Screen' : 'Full Screen'}
        </button>
        
        <div className='BuyCoffee'>
          <div className='CoffeeText'>
          Enjoying the game? Buy us a coffee to show your support!
          </div>
        <BuyMeCoffee />
        </div>
        {/* <OutstreamVideoAd /> */}
      </div>
    )
}

export default NeonHorizonWeb