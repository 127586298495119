

export const silentHill2RemakeQuiz = {
    quizPath: 'silenthill',
    quizTitle: 'Silent Hill',
    quizHeader: "Think you know everything about Silent Hill 2? Test your knowledge and challenge yourself with this quiz to see how well you truly remember the haunting details of this classic survival horror game.",
    image: "/images/quizzes/silent-hill-2.jpg",
    data: [
    {
        question: "Who is the main protagonist of Silent Hill 2?",
        options: ["James Sunderland", "Harry Mason", "Heather Mason", "Pyramid Head"],
        correctAnswer: "James Sunderland"
    },
    {
        question: "What is the name of James Sunderland's deceased wife?",
        options: ["Mary", "Maria", "Angela", "Laura"],
        correctAnswer: "Mary"
    },
    {
        question: "Which iconic monster appears frequently in Silent Hill 2 and symbolizes James's guilt?",
        options: ["Nurse", "The Butcher", "Pyramid Head", "Twin Victim"],
        correctAnswer: "Pyramid Head"
    },
    {
        question: "What is the name of the first character that James meets upon entering silent hill?",
        options: ["Maria", "Mary", "Angela", "Laura"],
        correctAnswer: "Angela"
    },
    {
        question: "How many years ago did James' wife die?",
        options: ["4", "2", "3", "1"],
        correctAnswer: "3"
    },
    {
        question: "What is the first major location James explores in silent hill?",
        options: ["Toluca Prison", "Lakeview Hotel", "Blue Creek Apartments", "Wood Side Apartments"],
        correctAnswer: "Wood Side Apartments"
    },
    {
        question: "What is the name of the club that James and Maria pass through?",
        options: ["Heavens Night", "Club Inferno", "Paradise Lost", "Moonlight Lounge"],
        correctAnswer: "Heavens Night"
    },
    {
        question: "What is Eddie eating when James finds him in the bowling alley?",
        options: ["Pizza", "Burger", "Hot Dog", "Fries"],
        correctAnswer: "Pizza"
    },
    {
        question: "What state is Silent Hill located?",
        options: ["Vermont", "Maine", "Oregon", "New York"],
        correctAnswer: "Maine"
    },

    {
        question: "What item must James examine multiple times to trigger certain dialogue that hints at his guilt?",
        options: ["Mary's letter", "Photo of Mary", "The knife given by Angela", "Radio"],
        correctAnswer: "The knife given by Angela"
    },
    
    {
        question: "What role does Lisa Garland play in the original Silent Hill?",
        options: ["Nurse", "Police Officer", "Teacher", "Hotel Manager"],
        correctAnswer: "Nurse"
    },
    {
        question: "In Silent Hill 3, what is the name of the amusement park ride that nearly kills Heather?",
        options: ["Roller Coaster", "Ferris Wheel", "Haunted Mansion Ride", "Carousel"],
        correctAnswer: "Roller Coaster"
    },
    {
        question: "Which Silent Hill game features a storyline focused on Murphy Pendleton?",
        options: ["Silent Hill 4: The Room", "Silent Hill: Downpour", "Silent Hill: Origins", "Silent Hill: Homecoming"],
        correctAnswer: "Silent Hill: Downpour"
    },
    {
        question: "What item is used to save your game in the original Silent Hill?",
        options: ["Typewriter", "Tape Recorder", "Notepad", "Camera"],
        correctAnswer: "Notepad"
    },
    {
        question: "What is the name of the hospital that appears frequently in the Silent Hill series?",
        options: ["Alchemilla Hospital", "Brookhaven Hospital", "Saint Mary's Hospital", "Cedar Grove Sanitarium"],
        correctAnswer: "Alchemilla Hospital"
    },
    {
        question: "Which character is revealed to be the reincarnation of Alessa Gillespie in Silent Hill 3?",
        options: ["Heather Mason", "Maria", "Lisa Garland", "Angela Orosco"],
        correctAnswer: "Heather Mason"
    },
    {
        question: "In Silent Hill 4: The Room, how is the protagonist, Henry Townshend, trapped?",
        options: ["In a haunted mansion", "In his apartment", "In an abandoned hospital", "In a hotel"],
        correctAnswer: "In his apartment"
    },
    {
        question: "What is the primary weapon used by Travis Grady in Silent Hill: Origins?",
        options: ["A flashlight", "A crowbar", "His fists", "A revolver"],
        correctAnswer: "His fists"
    },
    {
        question: "Which Silent Hill game features multiple endings determined by psychological profiles?",
        options: ["Silent Hill: Shattered Memories", "Silent Hill 2", "Silent Hill: Homecoming", "Silent Hill 3"],
        correctAnswer: "Silent Hill: Shattered Memories"
    },
    {
        question: "What object does James Sunderland receive from Maria that symbolizes her connection to Mary in Silent Hill 2?",
        options: ["A letter", "A music box", "A photo", "A hairpin"],
        correctAnswer: "A music box"
    },
    {
        question: "In Silent Hill: Homecoming, what is the name of Alex Shepherd's younger brother?",
        options: ["Joshua", "Henry", "Travis", "James"],
        correctAnswer: "Joshua"
    },
    {
        question: "What item does Heather Mason use to defeat the final boss, God, in Silent Hill 3?",
        options: ["Seal of Metatron", "Aglaophotis", "Pendant", "Samurai Sword"],
        correctAnswer: "Pendant"
    },
    {
        question: "In Silent Hill: Shattered Memories, what is the unique gameplay mechanic that replaces combat?",
        options: ["Running from monsters", "Solving puzzles", "Shooting with a gun", "Hiding in lockers"],
        correctAnswer: "Running from monsters"
    },
    {
        question: "Which character from the original Silent Hill returns as a prominent figure in Silent Hill 3?",
        options: ["Harry Mason", "Cybil Bennett", "Dahlia Gillespie", "Vincent Smith"],
        correctAnswer: "Harry Mason"
    },
    {
        question: "In Silent Hill 2, what does the letter from Mary reveal by the end of the game?",
        options: ["It was blank", "It was a love letter", "It was a confession", "It was a suicide note"],
        correctAnswer: "It was blank"
    },
    {
        question: "What theme does Pyramid Head primarily represent in Silent Hill 2?",
        options: ["Punishment", "Redemption", "Fear", "Hope"],
        correctAnswer: "Punishment"
    },
    {
        question: "Which Silent Hill game features a storyline set largely in an orphanage?",
        options: ["Silent Hill: Origins", "Silent Hill: Downpour", "Silent Hill 4: The Room", "Silent Hill 3"],
        correctAnswer: "Silent Hill: Downpour"
    },
    {
        question: "In Silent Hill 4: The Room, what connects Henry's apartment to the strange alternate worlds?",
        options: ["A mysterious door", "A radio", "A series of holes", "A haunted mirror"],
        correctAnswer: "A series of holes"
    },
    {
        question: "What is the name of the cult's deity that Claudia Wolf seeks to resurrect in Silent Hill 3?",
        options: ["Valtiel", "Samael", "God", "Pyramid Head"],
        correctAnswer: "God"
    },
    {
        question: "What radio mechanic is used throughout the Silent Hill series to alert players of nearby monsters?",
        options: ["Static noise", "Beeping", "Music changes", "Flashing light"],
        correctAnswer: "Static noise"
    }

    ]
    
};

export const minecraftQuiz = {
    quizPath: 'minecraft',
    quizTitle: "Minecraft",
    quizHeader: "Are you a true Minecraft expert? Put your knowledge to the test with our challenging quiz and prove you know everything about the world of blocks, mobs, and crafting!",
    image: '/images/quizzes/minecraft.jpg',
    data: [
        {
            question: "What is the name of the dimension where the Ender Dragon resides?",
            options: ["The Nether", "The End", "The Overworld", "The Void"],
            correctAnswer: "The End"
        },
        {
            question: "Which item is required to craft a Nether portal?",
            options: ["Obsidian", "End Stone", "Bedrock", "Diamond Block"],
            correctAnswer: "Obsidian"
        },
        {
            question: "What do you need to tame a wolf?",
            options: ["Bone", "Raw Meat", "String", "Fish"],
            correctAnswer: "Bone"
        },
        {
            question: "Which hostile mob explodes when it gets close to the player?",
            options: ["Zombie", "Creeper", "Skeleton", "Enderman"],
            correctAnswer: "Creeper"
        },
        {
            question: "What is the maximum number of items that can fit in a stack (most items) in Minecraft?",
            options: ["16", "32", "64", "128"],
            correctAnswer: "64"
        },
        {
            question: "What enchantment allows you to breathe underwater?",
            options: ["Aqua Affinity", "Respiration", "Depth Strider", "Feather Falling"],
            correctAnswer: "Respiration"
        },
        {
            question: "Which block is needed to make a beacon function?",
            options: ["Emerald Block", "Gold Block", "Iron Block", "Diamond Block"],
            correctAnswer: "Iron Block"
        },
        {
            question: "What item can be used to cure a zombie villager?",
            options: ["Golden Apple", "Potion of Healing", "Bread", "Enchanted Book"],
            correctAnswer: "Golden Apple"
        },
        {
            question: "What tool do you use to collect honey from a beehive without angering the bees?",
            options: ["Bucket", "Glass Bottle", "Shears", "Empty Hand"],
            correctAnswer: "Glass Bottle"
        },
        {
            question: "Which of these mobs is passive and won't attack the player?",
            options: ["Blaze", "Ghast", "Sheep", "Spider"],
            correctAnswer: "Sheep"
        },
        {
            question: "What ingredient is required to make a Potion of Strength?",
            options: ["Blaze Powder", "Ghast Tear", "Nether Wart", "Magma Cream"],
            correctAnswer: "Blaze Powder"
        },
        {
            question: "Which material do you need to craft an Enchantment Table?",
            options: ["Obsidian", "Lapis Lazuli", "Emerald", "Gold"],
            correctAnswer: "Obsidian"
        },
        {
            question: "What do Endermen drop when killed?",
            options: ["Ender Pearls", "Bones", "Blaze Rods", "Rotten Flesh"],
            correctAnswer: "Ender Pearls"
        },
        {
            question: "Which animal can be ridden using a saddle?",
            options: ["Cow", "Pig", "Chicken", "Sheep"],
            correctAnswer: "Pig"
        },
        {
            question: "What is needed to craft TNT?",
            options: ["Sand and Gunpowder", "Coal and Sand", "Obsidian and Flint", "Stone and Blaze Powder"],
            correctAnswer: "Sand and Gunpowder"
        },
        {
            question: "Which item allows you to safely descend from high places?",
            options: ["Water Bucket", "Feather Falling Boots", "Hay Bale", "All of the above"],
            correctAnswer: "All of the above"
        },
        {
            question: "What color wool do you get from a pink sheep?",
            options: ["Pink", "White", "Red", "Purple"],
            correctAnswer: "Pink"
        },
        {
            question: "How do you obtain a Music Disc in Minecraft?",
            options: ["Killing a Creeper with a Skeleton's arrow", "Crafting with diamonds", "Trading with villagers", "Mining in the Nether"],
            correctAnswer: "Killing a Creeper with a Skeleton's arrow"
        },
        {
            question: "What happens if you try to sleep in the Nether?",
            options: ["The bed explodes", "You teleport", "Nothing happens", "You wake up in the Overworld"],
            correctAnswer: "The bed explodes"
        },
        {
            question: "Which of these items cannot be used as fuel in a furnace?",
            options: ["Wooden Pickaxe", "Bucket of Lava", "Stick", "Cobblestone"],
            correctAnswer: "Cobblestone"
        },
        {
            question: "How do you make an infinite water source?",
            options: ["Using two water buckets", "Using four water buckets", "Using a cauldron", "Using a bucket of ice"],
            correctAnswer: "Using two water buckets"
        },
        {
            question: "What item do you need to enter the End?",
            options: ["Eye of Ender", "Nether Star", "End Crystal", "Golden Carrot"],
            correctAnswer: "Eye of Ender"
        },
        {
            question: "Which mob can drop a Totem of Undying?",
            options: ["Evoker", "Vindicator", "Witch", "Zombie"],
            correctAnswer: "Evoker"
        },
        {
            question: "How do you breed turtles in Minecraft?",
            options: ["Using Seagrass", "Using Fish", "Using Kelp", "Using Sand"],
            correctAnswer: "Using Seagrass"
        },
        {
            question: "What type of wood is found in the Nether?",
            options: ["Crimson and Warped", "Oak and Birch", "Spruce and Acacia", "Jungle and Dark Oak"],
            correctAnswer: "Crimson and Warped"
        },
        {
            question: "What is the rarest ore in Minecraft?",
            options: ["Emerald", "Diamond", "Gold", "Lapis Lazuli"],
            correctAnswer: "Emerald"
        },
        {
            question: "Which villager profession gives you enchanted books?",
            options: ["Librarian", "Blacksmith", "Farmer", "Cleric"],
            correctAnswer: "Librarian"
        },
        {
            question: "How do you obtain a dragon egg?",
            options: ["Defeat the Ender Dragon", "Mine it with a diamond pickaxe", "Trade with villagers", "Craft it"],
            correctAnswer: "Defeat the Ender Dragon"
        },
        {
            question: "What effect does a Potion of Turtle Master give you?",
            options: ["Resistance and Slowness", "Speed and Night Vision", "Fire Resistance and Jump Boost", "Strength and Invisibility"],
            correctAnswer: "Resistance and Slowness"
        },
        {
            question: "Which block can Endermen not pick up?",
            options: ["Obsidian", "Grass Block", "Dirt", "Sand"],
            correctAnswer: "Obsidian"
        }
    ]
};



export const gamingHistoryQuiz = {
    quizPath: 'gaminghistory',
    quizTitle: "Gaming History",
    quizHeader: "Think you know everything about the history of video games? Put your knowledge to the test with our gaming history quiz and prove you're a true gamer!",
    image: '/images/quizzes/gaming-history.png',
    data: [
        {
            question: "What is considered the first commercially successful arcade video game?",
            options: ["Pong", "Space Invaders", "Asteroids", "Pac-Man"],
            correctAnswer: "Pong"
        },
        {
            question: "Which year was the original Nintendo Entertainment System (NES) released in North America?",
            options: ["1983", "1985", "1987", "1990"],
            correctAnswer: "1985"
        },
        {
            question: "Which company developed the game 'Space Invaders'?",
            options: ["Atari", "Namco", "Taito", "Sega"],
            correctAnswer: "Taito"
        },
        {
            question: "Who is the creator of the Mario franchise?",
            options: ["Shigeru Miyamoto", "Hideo Kojima", "Yu Suzuki", "Satoru Iwata"],
            correctAnswer: "Shigeru Miyamoto"
        },
        {
            question: "What was the first home console to feature an online gaming network?",
            options: ["Dreamcast", "PlayStation 2", "Xbox", "GameCube"],
            correctAnswer: "Dreamcast"
        },
        {
            question: "Which game is credited with pioneering the first-person shooter (FPS) genre?",
            options: ["Wolfenstein 3D", "Doom", "Quake", "GoldenEye 007"],
            correctAnswer: "Wolfenstein 3D"
        },
        {
            question: "Which of the following is the best-selling video game console of all time?",
            options: ["PlayStation 2", "Nintendo Switch", "Xbox 360", "PlayStation 4"],
            correctAnswer: "PlayStation 2"
        },
        {
            question: "What year was the iconic game 'The Legend of Zelda: Ocarina of Time' released?",
            options: ["1996", "1997", "1998", "1999"],
            correctAnswer: "1998"
        },
        {
            question: "What was the name of the ill-fated gaming console released by Atari in 1993?",
            options: ["Atari Jaguar", "Atari Lynx", "Atari Panther", "Atari Falcon"],
            correctAnswer: "Atari Jaguar"
        },
        {
            question: "Which game is often credited with creating the 'battle royale' genre?",
            options: ["PUBG", "Fortnite", "DayZ", "H1Z1"],
            correctAnswer: "PUBG"
        },
        {
            question: "Who is the protagonist of the Halo series?",
            options: ["Master Chief", "Commander Shepard", "Nathan Drake", "Marcus Fenix"],
            correctAnswer: "Master Chief"
        },
        {
            question: "Which game introduced the character of Solid Snake?",
            options: ["Metal Gear", "Metal Gear Solid", "Metal Gear Rising", "Splinter Cell"],
            correctAnswer: "Metal Gear"
        },
        {
            question: "Which year did Sony release the first PlayStation console?",
            options: ["1993", "1994", "1995", "1996"],
            correctAnswer: "1994"
        },
        {
            question: "What is the name of the main character in the Legend of Zelda series?",
            options: ["Link", "Zelda", "Ganondorf", "Sheik"],
            correctAnswer: "Link"
        },
        {
            question: "Which game was the first to feature the 'Konami Code'?",
            options: ["Gradius", "Contra", "Castlevania", "Metal Gear"],
            correctAnswer: "Gradius"
        },
        {
            question: "What year was Pac-Man first released?",
            options: ["1979", "1980", "1981", "1982"],
            correctAnswer: "1980"
        },
        {
            question: "Which popular game series is known for the phrase 'Finish Him'?",
            options: ["Mortal Kombat", "Street Fighter", "Tekken", "Virtua Fighter"],
            correctAnswer: "Mortal Kombat"
        },
        {
            question: "What was the original name of Mario before he was called 'Mario'?",
            options: ["Jumpman", "Carpenter", "Luigi", "Plumber"],
            correctAnswer: "Jumpman"
        },
        {
            question: "What is the highest-grossing arcade game of all time?",
            options: ["Pac-Man", "Space Invaders", "Street Fighter II", "Donkey Kong"],
            correctAnswer: "Pac-Man"
        },
        {
            question: "Which console was the first to use optical discs instead of cartridges?",
            options: ["PlayStation", "Sega Saturn", "Dreamcast", "GameCube"],
            correctAnswer: "Sega Saturn"
        },
        {
            question: "What game is known for its character 'Lara Croft'?",
            options: ["Tomb Raider", "Uncharted", "Prince of Persia", "Assassin's Creed"],
            correctAnswer: "Tomb Raider"
        },
        {
            question: "Which game series features the character 'Samus Aran'?",
            options: ["Metroid", "Mega Man", "Castlevania", "Star Fox"],
            correctAnswer: "Metroid"
        },
        {
            question: "Which game is credited with popularizing the open-world genre?",
            options: ["Grand Theft Auto III", "The Legend of Zelda", "The Witcher 3", "Assassin's Creed"],
            correctAnswer: "Grand Theft Auto III"
        },
        {
            question: "What was Sonic the Hedgehog's first appearance on a gaming console?",
            options: ["Sega Genesis", "Sega Master System", "Sega Saturn", "Sega Dreamcast"],
            correctAnswer: "Sega Genesis"
        },
        {
            question: "Which handheld console was the first to feature color graphics?",
            options: ["Game Boy Color", "Atari Lynx", "Game Gear", "Neo Geo Pocket"],
            correctAnswer: "Atari Lynx"
        },
        {
            question: "What year was the PlayStation 2 released?",
            options: ["1999", "2000", "2001", "2002"],
            correctAnswer: "2000"
        },
        {
            question: "Which game developer is known for creating the Elder Scrolls series?",
            options: ["Bethesda", "Blizzard", "BioWare", "Square Enix"],
            correctAnswer: "Bethesda"
        },
        {
            question: "Which game introduced the world to 'Tetris'?",
            options: ["Game Boy", "Atari", "Nintendo Entertainment System", "Arcade"],
            correctAnswer: "Game Boy"
        },
        {
            question: "Which gaming console introduced motion-sensing technology to a mainstream audience?",
            options: ["Nintendo Wii", "Xbox Kinect", "PlayStation Move", "Sega Activator"],
            correctAnswer: "Nintendo Wii"
        },
        {
            question: "Which famous cheat code is known as the 'Konami Code'?",
            options: ["Up, Up, Down, Down, Left, Right, Left, Right, B, A", "Left, Right, Left, Right, A, B, A, B", "Up, Down, Up, Down, A, B, Start", "A, B, B, A, Left, Right"],
            correctAnswer: "Up, Up, Down, Down, Left, Right, Left, Right, B, A"
        }
    ]
};



export const playstationHistoryQuiz = {
    quizPath: 'playstationhistory',
    quizTitle: "Playstation History",
    quizHeader: "Are you a true PlayStation fan? Take this ultimate PlayStation history quiz to test your knowledge about the evolution of the beloved gaming brand!",
    image: "/images/quizzes/playstation-history.png",
    data: [
        {
            question: "In what year was the original PlayStation console released in Japan?",
            options: ["1993", "1994", "1995", "1996"],
            correctAnswer: "1994"
        },
        {
            question: "Which game is considered the best-selling game for the original PlayStation?",
            options: ["Final Fantasy VII", "Gran Turismo", "Tekken 3", "Crash Bandicoot"],
            correctAnswer: "Gran Turismo"
        },
        {
            question: "Who developed the PlayStation console in partnership with Sony before a split led to the creation of a competing console?",
            options: ["Nintendo", "Sega", "Atari", "Microsoft"],
            correctAnswer: "Nintendo"
        },
        {
            question: "What was the name of Sony's online service for PlayStation 2?",
            options: ["PSN", "Sony Online", "PlayOnline", "PlayStation Network"],
            correctAnswer: "PlayOnline"
        },
        {
            question: "Which PlayStation console introduced the concept of trophies to track player achievements?",
            options: ["PlayStation 2", "PlayStation 3", "PlayStation 4", "PlayStation 5"],
            correctAnswer: "PlayStation 3"
        },
        {
            question: "What was the name of the handheld console that Sony released in 2004 to compete with the Nintendo DS?",
            options: ["PlayStation Portable (PSP)", "PS Vita", "PlayStation Go", "PlayStation Mini"],
            correctAnswer: "PlayStation Portable (PSP)"
        },
        {
            question: "Which iconic game character debuted alongside the PlayStation brand and became a mascot?",
            options: ["Crash Bandicoot", "Spyro the Dragon", "Ratchet", "Sly Cooper"],
            correctAnswer: "Crash Bandicoot"
        },
        {
            question: "Which PlayStation 4 exclusive was awarded Game of the Year in 2018 at The Game Awards?",
            options: ["God of War", "Horizon Zero Dawn", "Uncharted 4: A Thief's End", "The Last of Us Part II"],
            correctAnswer: "God of War"
        },
        {
            question: "What feature did the PlayStation 2 have that helped make it the best-selling console of all time?",
            options: ["DVD Player", "Online Play", "Bluetooth Controllers", "Motion Control"],
            correctAnswer: "DVD Player"
        },
        {
            question: "What was the code name for the PlayStation 4 during its development?",
            options: ["Orbis", "Morpheus", "Neo", "Andromeda"],
            correctAnswer: "Orbis"
        }
    ]
};

export const matchGameWithDeveloperQuiz = { 
    quizPath: 'matchgameswithdevelopers',
    quizTitle: "Matching Games with Developers",
    quizHeader: "Think you know which developers made some of the most iconic games? Take this quiz to match the games with their creators and prove your gaming industry expertise!",
    image: "/images/quizzes/gaming-trivia.png",
    data: [
        {
            question: "Who developed 'The Legend of Zelda: Breath of the Wild'?",
            options: ["Nintendo", "Ubisoft", "Square Enix", "Sony Santa Monica"],
            correctAnswer: "Nintendo"
        },
        {
            question: "Which studio is behind the 'The Witcher' series?",
            options: ["CD Projekt Red", "BioWare", "Bethesda", "Rockstar Games"],
            correctAnswer: "CD Projekt Red"
        },
        {
            question: "Who developed 'Grand Theft Auto V'?",
            options: ["Rockstar Games", "Activision", "Ubisoft", "Electronic Arts"],
            correctAnswer: "Rockstar Games"
        },
        {
            question: "Which company created 'Halo: Combat Evolved'?",
            options: ["Bungie", "343 Industries", "Epic Games", "Infinity Ward"],
            correctAnswer: "Bungie"
        },
        {
            question: "Who is the developer of 'Dark Souls'?",
            options: ["FromSoftware", "Capcom", "Square Enix", "Bandai Namco"],
            correctAnswer: "FromSoftware"
        },
        {
            question: "Which studio developed 'Fortnite'?",
            options: ["Epic Games", "Respawn Entertainment", "Gearbox Software", "Blizzard Entertainment"],
            correctAnswer: "Epic Games"
        },
        {
            question: "Who developed 'The Last of Us'?",
            options: ["Naughty Dog", "Insomniac Games", "Santa Monica Studio", "Ubisoft"],
            correctAnswer: "Naughty Dog"
        },
        {
            question: "Which company is behind 'Assassin's Creed'?",
            options: ["Ubisoft", "Square Enix", "Electronic Arts", "CD Projekt Red"],
            correctAnswer: "Ubisoft"
        },
        {
            question: "Who developed 'Overwatch'?",
            options: ["Blizzard Entertainment", "Valve", "Riot Games", "Epic Games"],
            correctAnswer: "Blizzard Entertainment"
        },
        {
            question: "Which studio created 'Red Dead Redemption 2'?",
            options: ["Rockstar Games", "CD Projekt Red", "Bethesda", "Naughty Dog"],
            correctAnswer: "Rockstar Games"
        },
        {
            question: "Which developer created 'Half-Life'?",
            options: ["Valve", "Gearbox Software", "Bungie", "Epic Games"],
            correctAnswer: "Valve"
        },
        {
            question: "Who developed 'Metal Gear Solid'?",
            options: ["Konami", "Capcom", "Square Enix", "Ubisoft"],
            correctAnswer: "Konami"
        },
        {
            question: "Which studio is known for developing 'God of War'?",
            options: ["Santa Monica Studio", "Naughty Dog", "CD Projekt Red", "Ubisoft"],
            correctAnswer: "Santa Monica Studio"
        },
        {
            question: "Who developed 'Minecraft'?",
            options: ["Mojang", "Microsoft", "Epic Games", "Square Enix"],
            correctAnswer: "Mojang"
        },
        {
            question: "Which company developed 'Final Fantasy XV'?",
            options: ["Square Enix", "Capcom", "Bandai Namco", "Sega"],
            correctAnswer: "Square Enix"
        },
        {
            question: "Who developed 'The Sims'?",
            options: ["Maxis", "Electronic Arts", "Ubisoft", "Activision"],
            correctAnswer: "Maxis"
        },
        {
            question: "Which studio created 'Borderlands'?",
            options: ["Gearbox Software", "2K Games", "Bethesda", "Epic Games"],
            correctAnswer: "Gearbox Software"
        },
        {
            question: "Who developed 'Diablo III'?",
            options: ["Blizzard Entertainment", "Valve", "Square Enix", "Gearbox Software"],
            correctAnswer: "Blizzard Entertainment"
        },
        {
            question: "Which company developed 'Street Fighter'?",
            options: ["Capcom", "SNK", "Bandai Namco", "Nintendo"],
            correctAnswer: "Capcom"
        },
        {
            question: "Who is the developer of 'Mass Effect'?",
            options: ["BioWare", "Bungie", "Square Enix", "CD Projekt Red"],
            correctAnswer: "BioWare"
        },
        {
            question: "Which studio created 'Uncharted'?",
            options: ["Naughty Dog", "Santa Monica Studio", "Insomniac Games", "Rockstar Games"],
            correctAnswer: "Naughty Dog"
        },
        {
            question: "Who developed 'Destiny'?",
            options: ["Bungie", "Respawn Entertainment", "Infinity Ward", "Valve"],
            correctAnswer: "Bungie"
        },
        {
            question: "Which company created 'Sonic the Hedgehog'?",
            options: ["Sega", "Nintendo", "Capcom", "Konami"],
            correctAnswer: "Sega"
        },
        {
            question: "Who developed 'Fallout 4'?",
            options: ["Bethesda", "Obsidian Entertainment", "BioWare", "Gearbox Software"],
            correctAnswer: "Bethesda"
        },
        {
            question: "Which studio is behind 'Splatoon'?",
            options: ["Nintendo", "Sega", "Sony Santa Monica", "Epic Games"],
            correctAnswer: "Nintendo"
        },
        {
            question: "Who developed 'Resident Evil 2'?",
            options: ["Capcom", "Konami", "Square Enix", "Bandai Namco"],
            correctAnswer: "Capcom"
        },
        {
            question: "Which company developed 'The Elder Scrolls V: Skyrim'?",
            options: ["Bethesda", "Blizzard", "Square Enix", "2K Games"],
            correctAnswer: "Bethesda"
        },
        {
            question: "Who developed 'Far Cry 5'?",
            options: ["Ubisoft", "Activision", "Square Enix", "Rockstar Games"],
            correctAnswer: "Ubisoft"
        },
        {
            question: "Which studio created 'Horizon Zero Dawn'?",
            options: ["Guerrilla Games", "Insomniac Games", "Naughty Dog", "Ubisoft"],
            correctAnswer: "Guerrilla Games"
        },
        {
            question: "Who developed 'Death Stranding'?",
            options: ["Kojima Productions", "Konami", "Square Enix", "Capcom"],
            correctAnswer: "Kojima Productions"
        },
        {
            question: "Which company is behind 'Pokemon Red and Blue'?",
            options: ["Game Freak", "Nintendo", "Capcom", "Bandai Namco"],
            correctAnswer: "Game Freak"
        },
        {
            question: "Who developed 'God of War (2018)'?",
            options: ["Santa Monica Studio", "Naughty Dog", "Square Enix", "Epic Games"],
            correctAnswer: "Santa Monica Studio"
        },
        {
            question: "Which company created 'Dragon Age: Inquisition'?",
            options: ["BioWare", "Bethesda", "Square Enix", "Capcom"],
            correctAnswer: "BioWare"
        },
        {
            question: "Who developed 'Cyberpunk 2077'?",
            options: ["CD Projekt Red", "Rockstar Games", "Ubisoft", "Electronic Arts"],
            correctAnswer: "CD Projekt Red"
        },
        {
            question: "Which studio developed 'Titanfall 2'?",
            options: ["Respawn Entertainment", "Infinity Ward", "Epic Games", "Valve"],
            correctAnswer: "Respawn Entertainment"
        },
        {
            question: "Who is the developer of 'The Legend of Zelda: Ocarina of Time'?",
            options: ["Nintendo", "Capcom", "Square Enix", "Sony Santa Monica"],
            correctAnswer: "Nintendo"
        },
        {
            question: "Which studio created 'Doom (2016)'?",
            options: ["id Software", "Bethesda", "Epic Games", "Valve"],
            correctAnswer: "id Software"
        },
        {
            question: "Who developed 'The Elder Scrolls Online'?",
            options: ["ZeniMax Online Studios", "Bethesda", "Blizzard Entertainment", "CD Projekt Red"],
            correctAnswer: "ZeniMax Online Studios"
        },
        {
            question: "Which company created 'Kingdom Hearts'?",
            options: ["Square Enix", "Capcom", "Bandai Namco", "Nintendo"],
            correctAnswer: "Square Enix"
        },
        {
            question: "Who developed 'Starcraft'?",
            options: ["Blizzard Entertainment", "Valve", "Epic Games", "Ubisoft"],
            correctAnswer: "Blizzard Entertainment"
        },
        {
            question: "Which studio is behind the creation of 'Just Cause'?",
            options: ["Avalanche Studios", "Square Enix", "Ubisoft", "Bethesda"],
            correctAnswer: "Avalanche Studios"
        },
        {
            question: "Who developed 'Metal Gear Solid V: The Phantom Pain'?",
            options: ["Kojima Productions", "Konami", "Capcom", "Square Enix"],
            correctAnswer: "Kojima Productions"
        },
        {
            question: "Which studio developed 'Control'?",
            options: ["Remedy Entertainment", "Epic Games", "Ubisoft", "Rockstar Games"],
            correctAnswer: "Remedy Entertainment"
        },
        {
            question: "Who developed 'Battlefield 1'?",
            options: ["DICE", "Infinity Ward", "Electronic Arts", "Ubisoft"],
            correctAnswer: "DICE"
        },
        {
            question: "Which company created 'Persona 5'?",
            options: ["Atlus", "Bandai Namco", "Capcom", "Square Enix"],
            correctAnswer: "Atlus"
        },
        {
            question: "Who developed 'Hitman (2016)'?",
            options: ["IO Interactive", "Ubisoft", "Square Enix", "Rockstar Games"],
            correctAnswer: "IO Interactive"
        },
        {
            question: "Which studio is responsible for developing 'Rocket League'?",
            options: ["Psyonix", "Epic Games", "Valve", "Bethesda"],
            correctAnswer: "Psyonix"
        }
    ]
};




export const skyrimQuiz = {
    quizPath: 'skyrim',
    quizTitle: "Skyrim",
    quizHeader: "Think you're the Dragonborn? Test your knowledge about Skyrim and its epic lore, quests, and characters!",
    image: "/images/quizzes/skyrim.jpeg",
    data: [
        {
            question: "What is the name of the Dragonborn protagonist in Skyrim?",
            options: ["Dovahkiin", "Dragonheart", "Alduin", "Septim"],
            correctAnswer: "Dovahkiin"
        },
        {
            question: "Which city is home to the Thieves Guild in Skyrim?",
            options: ["Riften", "Solitude", "Whiterun", "Windhelm"],
            correctAnswer: "Riften"
        },
        {
            question: "What is the name of the leader of the Greybeards?",
            options: ["Paarthurnax", "Ulfric", "Arngeir", "Esbern"],
            correctAnswer: "Paarthurnax"
        },
        {
            question: "Which Daedric Prince is known as the 'Prince of Madness'?",
            options: ["Sheogorath", "Molag Bal", "Mehrunes Dagon", "Meridia"],
            correctAnswer: "Sheogorath"
        },
        {
            question: "What shout is used to summon a dragon?",
            options: ["Call Dragon", "Dragonrend", "Fire Breath", "Fus Ro Dah"],
            correctAnswer: "Call Dragon"
        },
        {
            question: "Who is the Jarl of Whiterun at the beginning of the game?",
            options: ["Balgruuf the Greater", "Ulfric Stormcloak", "Elisif the Fair", "Skald the Elder"],
            correctAnswer: "Balgruuf the Greater"
        },
        {
            question: "What is the name of the dragon that serves as the main antagonist in Skyrim?",
            options: ["Alduin", "Odahviing", "Mirmulnir", "Paarthurnax"],
            correctAnswer: "Alduin"
        },
        {
            question: "Which faction is dedicated to hunting vampires in Skyrim?",
            options: ["The Dawnguard", "The Blades", "The Companions", "The Dark Brotherhood"],
            correctAnswer: "The Dawnguard"
        },
        {
            question: "What is the reward for completing the quest 'The Black Star'?",
            options: ["Azura's Star", "Mehrunes' Razor", "The Ebony Blade", "The Wabbajack"],
            correctAnswer: "Azura's Star"
        },
        {
            question: "What race is known for their natural resistance to cold and ability to breathe underwater?",
            options: ["Argonian", "Nord", "Khajiit", "Dunmer"],
            correctAnswer: "Argonian"
        },
        {
            question: "What are the names of the twin moons that appear in Skyrim's night sky?",
            options: ["Masser and Secunda", "Nirn and Oblivion", "Aetherius and Magnus", "Akatosh and Kynareth"],
            correctAnswer: "Masser and Secunda"
        },
        {
            question: "Which item must be stolen to complete the quest 'Loud and Clear' for the Thieves Guild?",
            options: ["Gold Urn", "Queen Bee Statue", "Silver Candlestick", "Jeweled Goblet"],
            correctAnswer: "Queen Bee Statue"
        },
        {
            question: "What type of creature is Nahagliiv?",
            options: ["Dragon", "Giant", "Werewolf", "Troll"],
            correctAnswer: "Dragon"
        },
        {
            question: "Which school of magic focuses on spells that manipulate undead creatures?",
            options: ["Conjuration", "Restoration", "Destruction", "Alteration"],
            correctAnswer: "Conjuration"
        },
        {
            question: "What is the name of the Khajiit follower who can join the Dragonborn?",
            options: ["Kharjo", "J'zargo", "Ma'randru-jo", "Azzadal"],
            correctAnswer: "Kharjo"
        },
        {
            question: "What item is needed to access the College of Winterhold?",
            options: ["The Staff of Magnus", "A Spell Tome", "A Dragonstone", "The Amulet of Kings"],
            correctAnswer: "A Spell Tome"
        },
        {
            question: "Which Daedric artifact is also known as the 'Oblivion Walker'?",
            options: ["Mehrunes' Razor", "Skull of Corruption", "Oghma Infinium", "Ebony Mail"],
            correctAnswer: "Oghma Infinium"
        },
        {
            question: "Who is the main antagonist of the Dark Brotherhood questline?",
            options: ["Astrid", "Cicero", "Amaund Motierre", "Emperor Titus Mede II"],
            correctAnswer: "Astrid"
        },
        {
            question: "What is the name of the realm of Oblivion ruled by Hircine?",
            options: ["The Hunting Grounds", "Apocrypha", "The Shivering Isles", "Coldharbour"],
            correctAnswer: "The Hunting Grounds"
        },
        {
            question: "What power do Nords have as a racial ability?",
            options: ["Battle Cry", "Invisibility", "Night Eye", "Command Animal"],
            correctAnswer: "Battle Cry"
        },
        {
            question: "What is the name of the inn in Whiterun?",
            options: ["The Bannered Mare", "The Sleeping Giant Inn", "The Bee and Barb", "The Winking Skeever"],
            correctAnswer: "The Bannered Mare"
        },
        {
            question: "What ingredient is NOT used to craft health potions?",
            options: ["Frost Salts", "Blue Mountain Flower", "Wheat", "Giant's Toe"],
            correctAnswer: "Frost Salts"
        },
        {
            question: "Who do you need to defeat to complete the Companions questline?",
            options: ["Kodlak Whitemane", "Silver Hand Leader", "Vilkas", "Farkas"],
            correctAnswer: "Silver Hand Leader"
        },
        {
            question: "Which Daedric Prince is associated with 'Dreams and Nightmares'?",
            options: ["Vaermina", "Hermaeus Mora", "Molag Bal", "Meridia"],
            correctAnswer: "Vaermina"
        },
        {
            question: "What is the name of the High King killed by Ulfric Stormcloak?",
            options: ["Torygg", "Balgruuf", "Skald", "Elisif"],
            correctAnswer: "Torygg"
        },
        {
            question: "What material is required to craft Dragonplate Armor?",
            options: ["Dragon Bone", "Ebony Ingot", "Moonstone", "Chitin Plate"],
            correctAnswer: "Dragon Bone"
        },
        {
            question: "Which dragon shouts its own name as it lands to confront the Dragonborn?",
            options: ["Odahviing", "Alduin", "Mirmulnir", "Nahagliiv"],
            correctAnswer: "Odahviing"
        },
        {
            question: "Who is the leader of the Blades in Skyrim?",
            options: ["Delphine", "Esbern", "Paarthurnax", "Ulfric"],
            correctAnswer: "Delphine"
        },
        {
            question: "What skill tree does 'Lockpicking' fall under in Skyrim?",
            options: ["Stealth", "Combat", "Magic", "Alchemy"],
            correctAnswer: "Stealth"
        },
        {
            question: "Which hold in Skyrim is home to the city of Markarth?",
            options: ["The Reach", "The Pale", "Eastmarch", "The Rift"],
            correctAnswer: "The Reach"
        },
        {
            question: "What is the name of the orb that Ancano tries to use to control the College of Winterhold?",
            options: ["The Eye of Magnus", "The Black Star", "The Staff of Chaos", "The Sigil Stone"],
            correctAnswer: "The Eye of Magnus"
        },
        {
            question: "Which dragon can be summoned to aid the Dragonborn in battle?",
            options: ["Odahviing", "Alduin", "Sahloknir", "Nahagliiv"],
            correctAnswer: "Odahviing"
        },
        {
            question: "Which shout translates to 'Storm Call'?",
            options: ["Strun Bah Qo", "Yol Toor Shul", "Fus Ro Dah", "Laas Yah Nir"],
            correctAnswer: "Strun Bah Qo"
        },
        {
            question: "What is the name of the blacksmith in Riverwood?",
            options: ["Alvor", "Belethor", "Adrianne", "Grelka"],
            correctAnswer: "Alvor"
        },
        {
            question: "Who betrays the Dragonborn during the quest 'Diplomatic Immunity'?",
            options: ["Elisif", "Delphine", "Malborn", "Ulfric"],
            correctAnswer: "Malborn"
        },
        {
            question: "What type of spell is 'Candlelight'?",
            options: ["Alteration", "Restoration", "Conjuration", "Illusion"],
            correctAnswer: "Alteration"
        },
        {
            question: "What is the name of the island featured in the Dragonborn DLC?",
            options: ["Solstheim", "Summerset", "Stros M'Kai", "Bleak Falls Barrow"],
            correctAnswer: "Solstheim"
        },
        {
            question: "Which dragon priest mask grants increased Magicka regeneration?",
            options: ["Morokei", "Volsung", "Otar", "Krosis"],
            correctAnswer: "Morokei"
        },
        {
            question: "What is the name of the Dragonborn's housecarl in Whiterun?",
            options: ["Lydia", "Aela", "Farkas", "Vilkas"],
            correctAnswer: "Lydia"
        },
        {
            question: "Which shout is required to defeat Alduin in Sovngarde?",
            options: ["Dragonrend", "Unrelenting Force", "Call Dragon", "Whirlwind Sprint"],
            correctAnswer: "Dragonrend"
        },
        {
            question: "What is the name of the Daedric Prince of Knowledge and Memory?",
            options: ["Hermaeus Mora", "Malacath", "Vaermina", "Boethiah"],
            correctAnswer: "Hermaeus Mora"
        },
        {
            question: "Who teaches the Dragonborn how to use the Thu'um?",
            options: ["The Greybeards", "The Companions", "The Blades", "The Dark Brotherhood"],
            correctAnswer: "The Greybeards"
        },
        {
            question: "Which character is the leader of the Companions?",
            options: ["Kodlak Whitemane", "Vilkas", "Aela", "Farkas"],
            correctAnswer: "Kodlak Whitemane"
        },
        {
            question: "What is the name of the ancient Nordic tomb where you find the Dragonstone?",
            options: ["Bleak Falls Barrow", "Labyrinthian", "Sky Haven Temple", "Dustman's Cairn"],
            correctAnswer: "Bleak Falls Barrow"
        },
        {
            question: "Which hold is the city of Falkreath located in?",
            options: ["Falkreath Hold", "Haafingar", "The Rift", "Hjaalmarch"],
            correctAnswer: "Falkreath Hold"
        },
        {
            question: "What material is needed to craft Daedric armor?",
            options: ["Ebony Ingot", "Moonstone", "Orichalcum", "Quicksilver"],
            correctAnswer: "Ebony Ingot"
        },
        {
            question: "Which dragon was resurrected by Alduin near Kynesgrove?",
            options: ["Sahloknir", "Mirmulnir", "Paarthurnax", "Odahviing"],
            correctAnswer: "Sahloknir"
        },
        {
            question: "Who is the ghost encountered in the 'Lost to the Ages' quest?",
            options: ["Katria", "Lucien", "Narfi", "Roggvir"],
            correctAnswer: "Katria"
        }

    ]
};


export const haloQuiz = {
    quizPath: 'halo',
    quizTitle: "Halo",
    quizHeader: "Are you a true Spartan? Test your knowledge about the Halo universe and its epic battles, characters, and lore!",
    image: "/images/quizzes/halo.jpeg",
    data: [
        {
            question: "Who is the main protagonist of the original Halo trilogy?",
            options: ["Master Chief", "Arbiter", "Sergeant Johnson", "Cortana"],
            correctAnswer: "Master Chief"
        },
        {
            question: "What is the name of the artificial intelligence that assists Master Chief?",
            options: ["Cortana", "Serina", "Roland", "Isabel"],
            correctAnswer: "Cortana"
        },
        {
            question: "What is the primary alien alliance that serves as the antagonists in the Halo series?",
            options: ["The Covenant", "The Flood", "The Banished", "The Forerunners"],
            correctAnswer: "The Covenant"
        },
        {
            question: "Which Halo game introduced the character of The Arbiter as a playable protagonist?",
            options: ["Halo 2", "Halo 3", "Halo: Reach", "Halo 4"],
            correctAnswer: "Halo 2"
        },
        {
            question: "What is the name of the massive ringworld structures central to the Halo series' story?",
            options: ["Halo Rings", "Arc Systems", "Spartan Worlds", "Onyx Shields"],
            correctAnswer: "Halo Rings"
        },
        {
            question: "Who is the leader of the Covenant during the events of Halo 2?",
            options: ["Prophet of Truth", "Prophet of Regret", "Atriox", "Didact"],
            correctAnswer: "Prophet of Truth"
        },
        {
            question: "Which Halo game was developed by Bungie as their final Halo title?",
            options: ["Halo: Reach", "Halo 3", "Halo 4", "Halo: Combat Evolved"],
            correctAnswer: "Halo: Reach"
        },
        {
            question: "What is the name of the parasitic enemy that threatens all sentient life in the Halo universe?",
            options: ["The Flood", "The Gravemind", "The Swarm", "The Infested"],
            correctAnswer: "The Flood"
        },
        {
            question: "What is the designation of Master Chief?",
            options: ["Spartan-117", "Spartan-046", "Spartan-076", "Spartan-104"],
            correctAnswer: "Spartan-117"
        },
        {
            question: "Which game studio took over the Halo series after Bungie?",
            options: ["343 Industries", "Epic Games", "Infinity Ward", "Respawn Entertainment"],
            correctAnswer: "343 Industries"
        }
    ]
};


export const marioHistoryQuiz = {
    quizPath: 'mariohistory',
    quizTitle: "Mario History",
    quizHeader: "Think you know everything about Mario? Test your knowledge about the history of Mario games, from the classic platformers to the modern adventures!",
    image: "/images/quizzes/mario-history.jpg",
    data: [
        {
            question: "In which year was the original 'Super Mario Bros.' released?",
            options: ["1983", "1985", "1987", "1990"],
            correctAnswer: "1985"
        },
        {
            question: "What was the first game in which Mario appeared?",
            options: ["Super Mario Bros.", "Donkey Kong", "Mario Bros.", "Wrecking Crew"],
            correctAnswer: "Donkey Kong"
        },
        {
            question: "Which game introduced Mario's brother, Luigi?",
            options: ["Super Mario Bros.", "Mario Bros.", "Super Mario World", "Luigi's Mansion"],
            correctAnswer: "Mario Bros."
        },
        {
            question: "What is the name of the first 3D Mario game?",
            options: ["Super Mario 64", "Super Mario Galaxy", "Super Mario Sunshine", "Super Mario 3D Land"],
            correctAnswer: "Super Mario 64"
        },
        {
            question: "Which game introduced Yoshi as Mario's companion?",
            options: ["Super Mario World", "Super Mario Bros. 3", "Yoshi's Island", "Super Mario 64"],
            correctAnswer: "Super Mario World"
        },
        {
            question: "Which Mario game featured the debut of the character Bowser Jr.?",
            options: ["Super Mario Sunshine", "Super Mario 64", "Super Mario Galaxy", "New Super Mario Bros."],
            correctAnswer: "Super Mario Sunshine"
        },
        {
            question: "Which console was 'Super Mario Galaxy' originally released on?",
            options: ["GameCube", "Wii", "Nintendo 64", "Wii U"],
            correctAnswer: "Wii"
        },
        {
            question: "What is the name of Mario's dinosaur companion?",
            options: ["Yoshi", "Toad", "Koopa", "Birdo"],
            correctAnswer: "Yoshi"
        },
        {
            question: "Which Mario game is known for its use of a water-powered jetpack called FLUDD?",
            options: ["Super Mario Sunshine", "Super Mario 64", "Super Mario Odyssey", "Super Mario Galaxy"],
            correctAnswer: "Super Mario Sunshine"
        },
        {
            question: "Which game marked Mario's first adventure in space?",
            options: ["Super Mario Galaxy", "Super Mario 3D World", "Super Mario Sunshine", "Super Mario Odyssey"],
            correctAnswer: "Super Mario Galaxy"
        }
    ]
};


export const ocarinaOfTimeQuiz = {
    quizPath: 'ocarinaoftime',
    quizTitle: "Ocarina of Time",
    quizHeader: "Think you're a true Hero of Time? Test your knowledge about 'The Legend of Zelda: Ocarina of Time' with this challenging quiz!",
    image: "/images/quizzes/ocarina-of-time.jpg",
    data: [
        {
            question: "What year was 'The Legend of Zelda: Ocarina of Time' released?",
            options: ["1996", "1997", "1998", "1999"],
            correctAnswer: "1998"
        },
        {
            question: "What is the name of Link's fairy companion in 'Ocarina of Time'?",
            options: ["Navi", "Tatl", "Fi", "Midna"],
            correctAnswer: "Navi"
        },
        {
            question: "Who is the main antagonist in 'Ocarina of Time'?",
            options: ["Ganondorf", "Zant", "Vaati", "Ghirahim"],
            correctAnswer: "Ganondorf"
        },
        {
            question: "Which item is used to travel back and forth through time in the game?",
            options: ["Ocarina of Time", "Master Sword", "Hylian Shield", "Hookshot"],
            correctAnswer: "Ocarina of Time"
        },
        {
            question: "What is the name of the horse that Link rides in 'Ocarina of Time'?",
            options: ["Epona", "Roach", "Pegasus", "Storm"],
            correctAnswer: "Epona"
        },
        {
            question: "Which temple requires Link to obtain the Lens of Truth to navigate effectively?",
            options: ["Shadow Temple", "Forest Temple", "Fire Temple", "Water Temple"],
            correctAnswer: "Shadow Temple"
        },
        {
            question: "What is the name of the village where Link grows up?",
            options: ["Kokiri Forest", "Kakariko Village", "Lon Lon Ranch", "Gerudo Valley"],
            correctAnswer: "Kokiri Forest"
        },
        {
            question: "Which song is used to change day to night or vice versa?",
            options: ["Sun's Song", "Song of Time", "Song of Storms", "Epona's Song"],
            correctAnswer: "Sun's Song"
        },
        {
            question: "Who teaches Link the 'Song of Storms'?",
            options: ["Guru-Guru", "Impa", "Sheik", "Darunia"],
            correctAnswer: "Guru-Guru"
        },
        {
            question: "What is the final boss form of Ganondorf called in 'Ocarina of Time'?",
            options: ["Ganon", "Beast Ganondorf", "Dark Lord", "Demon King"],
            correctAnswer: "Ganon"
        }
    ]
};


export const streetFighterQuiz = {
    quizPath: 'streetfighter',
    quizTitle: "Street Fighter",
    quizHeader: "Are you a true World Warrior? Test your knowledge about the Street Fighter series and its legendary characters, moves, and history!",
    image: "/images/quizzes/street-fighter.jpg",
    data: [
        {
            question: "Who is the main protagonist of the Street Fighter series?",
            options: ["Ryu", "Ken", "Guile", "Chun-Li"],
            correctAnswer: "Ryu"
        },
        {
            question: "Which character is known for the move 'Sonic Boom'?",
            options: ["Guile", "Blanka", "E. Honda", "Dhalsim"],
            correctAnswer: "Guile"
        },
        {
            question: "What is the name of Ryu's signature move?",
            options: ["Hadouken", "Shoryuken", "Tatsumaki Senpukyaku", "Psycho Crusher"],
            correctAnswer: "Hadouken"
        },
        {
            question: "Which game introduced Chun-Li to the series?",
            options: ["Street Fighter II", "Street Fighter Alpha", "Street Fighter III", "Street Fighter IV"],
            correctAnswer: "Street Fighter II"
        },
        {
            question: "Who is the leader of the criminal organization Shadaloo?",
            options: ["M. Bison", "Vega", "Balrog", "Sagat"],
            correctAnswer: "M. Bison"
        },
        {
            question: "Which character is known for stretching their limbs during combat?",
            options: ["Dhalsim", "Zangief", "Blanka", "Ken"],
            correctAnswer: "Dhalsim"
        },
        {
            question: "Which Street Fighter game first introduced super combo moves?",
            options: ["Street Fighter II Turbo", "Street Fighter Alpha", "Super Street Fighter II Turbo", "Street Fighter III"],
            correctAnswer: "Super Street Fighter II Turbo"
        },
        {
            question: "Which character is famous for the move 'Spinning Bird Kick'?",
            options: ["Chun-Li", "Cammy", "Ryu", "Sakura"],
            correctAnswer: "Chun-Li"
        },
        {
            question: "What is the rivalry between Ryu and Ken based on?",
            options: ["They trained under the same master", "They are brothers", "They are both vying for Chun-Li's attention", "They are rivals in Shadaloo"],
            correctAnswer: "They trained under the same master"
        },
        {
            question: "Which character was originally a boss character in 'Street Fighter II' but later became playable?",
            options: ["Sagat", "Akuma", "Ryu", "Dan"],
            correctAnswer: "Sagat"
        }
    ]
};


export const mortalKombatQuiz = {
    quizPath: 'mortalkombat',
    quizTitle: "Mortal Kombat",
    quizHeader: "Do you have what it takes to survive Mortal Kombat? Test your knowledge about the iconic fighters, fatalities, and the bloody history of the Mortal Kombat series!",
    image: "/images/quizzes/mortal-kombat.jpg",
    data: [
        {
            question: "Who is the main protagonist of the original Mortal Kombat game?",
            options: ["Liu Kang", "Scorpion", "Sub-Zero", "Raiden"],
            correctAnswer: "Liu Kang"
        },
        {
            question: "Which character is known for the iconic line 'Get over here!'?",
            options: ["Scorpion", "Johnny Cage", "Kano", "Raiden"],
            correctAnswer: "Scorpion"
        },
        {
            question: "What is the name of the tournament in which the fighters compete?",
            options: ["Mortal Kombat", "The Deadly Alliance", "Armageddon", "The Shaolin Trials"],
            correctAnswer: "Mortal Kombat"
        },
        {
            question: "Who is the Emperor of Outworld?",
            options: ["Shao Kahn", "Shang Tsung", "Quan Chi", "Reptile"],
            correctAnswer: "Shao Kahn"
        },
        {
            question: "Which character can transform into other fighters?",
            options: ["Shang Tsung", "Reptile", "Smoke", "Noob Saibot"],
            correctAnswer: "Shang Tsung"
        },
        {
            question: "Which Mortal Kombat game introduced Fatalities?",
            options: ["Mortal Kombat", "Mortal Kombat II", "Mortal Kombat 3", "Mortal Kombat: Deadly Alliance"],
            correctAnswer: "Mortal Kombat"
        },
        {
            question: "Who is the Thunder God and protector of Earthrealm?",
            options: ["Raiden", "Liu Kang", "Fujin", "Kung Lao"],
            correctAnswer: "Raiden"
        },
        {
            question: "Which character is known for using a green energy ball and wearing sunglasses?",
            options: ["Johnny Cage", "Kano", "Sonya Blade", "Jax"],
            correctAnswer: "Johnny Cage"
        },
        {
            question: "Who is the younger brother of Sub-Zero, who takes up his mantle?",
            options: ["Kuai Liang", "Hanzo Hasashi", "Taven", "Kenshi"],
            correctAnswer: "Kuai Liang"
        },
        {
            question: "What is the name of the female assassin who wields fans as her weapons?",
            options: ["Kitana", "Mileena", "Jade", "Sonya Blade"],
            correctAnswer: "Kitana"
        }

    ]
};


export const gamingTriviasQuiz = {
    quizPath: 'gamingtrivia',
    quizTitle: "Gaming Trivia",
    quizHeader: "How well do you know the world of video games? From iconic characters to groundbreaking moments, this gaming trivia quiz will challenge your overall knowledge of the gaming universe!",
    image: "/images/quizzes/gaming-trivia.png",
    data: [
        {
            question: "What was the first video game to feature a fully 3D open world?",
            options: ["Super Mario 64", "The Legend of Zelda: Ocarina of Time", "Grand Theft Auto III", "Tomb Raider"],
            correctAnswer: "Super Mario 64"
        },
        {
            question: "Which console was the first to include an internal hard drive?",
            options: ["Xbox", "PlayStation 2", "GameCube", "Dreamcast"],
            correctAnswer: "Xbox"
        },
        {
            question: "Who is the main antagonist in the 'Half-Life' series?",
            options: ["G-Man", "Dr. Breen", "The Combine", "Adrian Shephard"],
            correctAnswer: "The Combine"
        },
        {
            question: "What is the name of the princess that Mario frequently rescues?",
            options: ["Princess Peach", "Princess Daisy", "Princess Zelda", "Toadette"],
            correctAnswer: "Princess Peach"
        },
        {
            question: "Which game is often considered the first true survival horror game?",
            options: ["Resident Evil", "Silent Hill", "Alone in the Dark", "Clock Tower"],
            correctAnswer: "Alone in the Dark"
        },
        {
            question: "What year was the original PlayStation released in North America?",
            options: ["1994", "1995", "1996", "1997"],
            correctAnswer: "1995"
        },
        {
            question: "Which popular battle royale game is developed by Epic Games?",
            options: ["PUBG", "Fortnite", "Apex Legends", "Call of Duty: Warzone"],
            correctAnswer: "Fortnite"
        },
        {
            question: "In 'The Legend of Zelda: Breath of the Wild', what is the name of the main antagonist?",
            options: ["Calamity Ganon", "Dark Link", "Vaati", "Majora"],
            correctAnswer: "Calamity Ganon"
        },
        {
            question: "Which video game character is known for saying 'Do a barrel roll!'?",
            options: ["Fox McCloud", "Falco Lombardi", "Peppy Hare", "Wolf O'Donnell"],
            correctAnswer: "Peppy Hare"
        },
        {
            question: "What is the best-selling video game of all time (as of 2024)?",
            options: ["Minecraft", "Tetris", "Grand Theft Auto V", "Wii Sports"],
            correctAnswer: "Minecraft"
        },
        {
            question: "What was the first video game to feature a female protagonist?",
            options: ["Metroid", "Tomb Raider", "Final Fantasy", "Street Fighter"],
            correctAnswer: "Metroid"
        },
        {
            question: "Which game series is known for the phrase 'War. War never changes.'?",
            options: ["Fallout", "Call of Duty", "Metal Gear Solid", "Battlefield"],
            correctAnswer: "Fallout"
        },
        {
            question: "What is the name of the iconic sword used by Cloud Strife in Final Fantasy VII?",
            options: ["Buster Sword", "Ultima Weapon", "Master Sword", "Masamune"],
            correctAnswer: "Buster Sword"
        },
        {
            question: "In what game would you find the character 'Tom Nook'?",
            options: ["Animal Crossing", "Harvest Moon", "Stardew Valley", "The Sims"],
            correctAnswer: "Animal Crossing"
        },
        {
            question: "What is the main antagonist's name in the 'Resident Evil' series?",
            options: ["Albert Wesker", "Leon Kennedy", "Jill Valentine", "Chris Redfield"],
            correctAnswer: "Albert Wesker"
        },
        {
            question: "Which game was the first to feature a boss battle?",
            options: ["Space Invaders", "Pac-Man", "Donkey Kong", "Mega Man"],
            correctAnswer: "Donkey Kong"
        },
        {
            question: "Which video game is widely considered to have popularized the 'first-person shooter' genre?",
            options: ["Doom", "Wolfenstein 3D", "Half-Life", "GoldenEye 007"],
            correctAnswer: "Doom"
        },
        {
            question: "In which game did 'Solid Snake' make his first appearance?",
            options: ["Metal Gear", "Metal Gear Solid", "Super Smash Bros.", "Metal Gear Rising"],
            correctAnswer: "Metal Gear"
        },
        {
            question: "Which company originally developed the Sonic the Hedgehog series?",
            options: ["Sega", "Nintendo", "Sony", "Capcom"],
            correctAnswer: "Sega"
        },
        {
            question: "What is the currency called in The Legend of Zelda series?",
            options: ["Rupees", "Gil", "Gold Coins", "Credits"],
            correctAnswer: "Rupees"
        },
        {
            question: "Who is the main protagonist of the 'Mass Effect' series?",
            options: ["Commander Shepard", "Garrus Vakarian", "Tali'Zorah", "Thane Krios"],
            correctAnswer: "Commander Shepard"
        },
        {
            question: "In what year was the first 'Assassin's Creed' game released?",
            options: ["2007", "2009", "2011", "2005"],
            correctAnswer: "2007"
        },
        {
            question: "Which character is famous for wearing a green tunic and wielding the Master Sword?",
            options: ["Link", "Zelda", "Ganondorf", "Epona"],
            correctAnswer: "Link"
        },
        {
            question: "Which game popularized the battle royale genre before Fortnite?",
            options: ["PUBG", "H1Z1", "Apex Legends", "DayZ"],
            correctAnswer: "PUBG"
        },
        {
            question: "What is the main objective in 'Portal'?",
            options: ["Escape the laboratory", "Rescue Princess Peach", "Collect the most coins", "Defeat the Combine"],
            correctAnswer: "Escape the laboratory"
        },
        {
            question: "Which gaming console is known for the tagline 'Now you're playing with power'?",
            options: ["NES", "Sega Genesis", "PlayStation", "Xbox"],
            correctAnswer: "NES"
        },
        {
            question: "What game is known for the character 'Big Daddy'?",
            options: ["BioShock", "Fallout", "Half-Life", "Dishonored"],
            correctAnswer: "BioShock"
        },
        {
            question: "In 'World of Warcraft', what is the name of the world the game takes place in?",
            options: ["Azeroth", "Tamriel", "Thedas", "Hyrule"],
            correctAnswer: "Azeroth"
        },
        {
            question: "What color is the ghost 'Blinky' in Pac-Man?",
            options: ["Red", "Pink", "Blue", "Orange"],
            correctAnswer: "Red"
        },
        {
            question: "What is the name of the company that created 'The Sims' franchise?",
            options: ["Maxis", "Blizzard", "Rockstar Games", "Valve"],
            correctAnswer: "Maxis"
        },
        {
            question: "Which game features the infamous character 'Handsome Jack'?",
            options: ["Borderlands 2", "Mass Effect 3", "The Witcher 3", "Fallout 4"],
            correctAnswer: "Borderlands 2"
        },
        {
            question: "What is the name of the protagonist in 'The Witcher' series?",
            options: ["Geralt of Rivia", "Ciri", "Yennefer", "Vesemir"],
            correctAnswer: "Geralt of Rivia"
        },
        {
            question: "What year was the original 'Doom' game released?",
            options: ["1993", "1995", "1991", "1998"],
            correctAnswer: "1993"
        },
        {
            question: "Which game franchise includes the character 'Dante'?",
            options: ["Devil May Cry", "God of War", "Bayonetta", "Ninja Gaiden"],
            correctAnswer: "Devil May Cry"
        },
        {
            question: "What is the main goal in the game 'Katamari Damacy'?",
            options: ["Roll up objects to make a large ball", "Rescue a princess", "Defeat the King", "Collect rare items"],
            correctAnswer: "Roll up objects to make a large ball"
        },
        {
            question: "Who is the final boss in 'Street Fighter II'?",
            options: ["M. Bison", "Ryu", "Ken", "Vega"],
            correctAnswer: "M. Bison"
        },
        {
            question: "In 'Minecraft', what material do you need to craft a Nether Portal?",
            options: ["Obsidian", "Iron Blocks", "End Stone", "Diamond"],
            correctAnswer: "Obsidian"
        },
        {
            question: "What is the name of the main character in the 'Halo' series?",
            options: ["Master Chief", "Marcus Fenix", "Commander Shepard", "Samus Aran"],
            correctAnswer: "Master Chief"
        },
        {
            question: "Which game features a villain named 'Andrew Ryan'?",
            options: ["BioShock", "Half-Life 2", "Deus Ex", "Far Cry"],
            correctAnswer: "BioShock"
        },
        {
            question: "What does 'Atari' mean in Japanese?",
            options: ["Success", "Hit the target", "Challenge", "Victory"],
            correctAnswer: "Hit the target"
        },
        {
            question: "Which game was the first to introduce 'Battle Passes'?",
            options: ["Dota 2", "Fortnite", "PUBG", "Call of Duty"],
            correctAnswer: "Dota 2"
        },
        {
            question: "What is the name of the sequel to the game 'The Last of Us'?",
            options: ["The Last of Us Part II", "The Last Stand", "Ellie's Story", "Survivors"],
            correctAnswer: "The Last of Us Part II"
        },
        {
            question: "Which character in Overwatch is known as the 'Swiss Medic'?",
            options: ["Mercy", "Ana", "Baptiste", "Moira"],
            correctAnswer: "Mercy"
        },
        {
            question: "What was the first video game to include a 'New Game Plus' mode?",
            options: ["Chrono Trigger", "Final Fantasy VI", "Super Mario World", "The Legend of Zelda"],
            correctAnswer: "Chrono Trigger"
        },
        {
            question: "What is the name of the villainous AI in 'Portal'?",
            options: ["GLaDOS", "Wheatley", "HAL 9000", "SHODAN"],
            correctAnswer: "GLaDOS"
        },
        {
            question: "Which game features the city of 'Rapture'?",
            options: ["BioShock", "Fallout", "Dishonored", "Deus Ex"],
            correctAnswer: "BioShock"
        },
        {
            question: "What type of creature is 'Spyro' in the Spyro the Dragon series?",
            options: ["Dragon", "Fox", "Hedgehog", "Bat"],
            correctAnswer: "Dragon"
        },
        {
            question: "Which game was the first to be referred to as a 'Metroidvania'?",
            options: ["Symphony of the Night", "Metroid Prime", "Castlevania", "Hollow Knight"],
            correctAnswer: "Symphony of the Night"
        },
        {
            question: "In which game does the character 'Nathan Drake' appear?",
            options: ["Uncharted", "Tomb Raider", "Far Cry", "Just Cause"],
            correctAnswer: "Uncharted"
        },
        {
            question: "What is the main color of Sonic the Hedgehog?",
            options: ["Blue", "Red", "Green", "Yellow"],
            correctAnswer: "Blue"
        },
        {
            question: "Which racing game series features 'Rainbow Road' as a recurring track?",
            options: ["Mario Kart", "Gran Turismo", "Need for Speed", "F-Zero"],
            correctAnswer: "Mario Kart"
        },
        {
            question: "What game popularized the concept of 'match-three' puzzle mechanics?",
            options: ["Bejeweled", "Tetris", "Candy Crush Saga", "Puzzle Bobble"],
            correctAnswer: "Bejeweled"
        }

    ]
};


export const witcherQuiz = {
    quizPath: 'witcher',
    quizTitle: "Witcher",
    quizHeader: "Think you know everything about Geralt of Rivia and the world of The Witcher? Test your knowledge about the story, characters, and lore of the iconic Witcher series!",
    image: "/images/quizzes/witcher.jpg",
    data: [
        {
            question: "What is the name of Geralt's horse?",
            options: ["Roach", "Plough", "Spirit", "Biscuit"],
            correctAnswer: "Roach"
        },
        {
            question: "What is the name of the sorceress who is Geralt's main love interest?",
            options: ["Yennefer", "Triss", "Ciri", "Fringilla"],
            correctAnswer: "Yennefer"
        },
        {
            question: "Which kingdom is Ciri the princess of?",
            options: ["Cintra", "Redania", "Nilfgaard", "Temeria"],
            correctAnswer: "Cintra"
        },
        {
            question: "What are Witchers trained to do?",
            options: ["Hunt monsters", "Cast powerful spells", "Rule kingdoms", "Steal artifacts"],
            correctAnswer: "Hunt monsters"
        },
        {
            question: "Who is the author of 'The Witcher' book series?",
            options: ["Andrzej Sapkowski", "J.R.R. Tolkien", "George R.R. Martin", "Patrick Rothfuss"],
            correctAnswer: "Andrzej Sapkowski"
        },
        {
            question: "What is the name of the powerful elven sorceress that helps Geralt and Ciri?",
            options: ["Fringilla Vigo", "Yennefer of Vengerberg", "Philippa Eilhart", "Francesca Findabair"],
            correctAnswer: "Yennefer of Vengerberg"
        },
        {
            question: "What kind of creature is a Leshen?",
            options: ["Forest spirit", "Dragon", "Vampire", "Werewolf"],
            correctAnswer: "Forest spirit"
        },
        {
            question: "What is the name of Geralt's mentor?",
            options: ["Vesemir", "Lambert", "Eskel", "Gaunter"],
            correctAnswer: "Vesemir"
        },
        {
            question: "What is the name of the card game featured in The Witcher 3?",
            options: ["Gwent", "Triple Triad", "Pazaak", "Caravan"],
            correctAnswer: "Gwent"
        },
        {
            question: "Which of these monsters is Geralt most likely to fight?",
            options: ["Griffin", "Balrog", "Goblin", "Dragonborn"],
            correctAnswer: "Griffin"
        }
    ]
};


export const csgoQuiz = {
    quizPath: 'csgo',
    quizTitle: "CSGO",
    quizHeader: "Think you know everything about Counter-Strike: Global Offensive? Test your knowledge about the weapons, maps, and strategies of this iconic shooter!",
    image: "/images/quizzes/csgo.png",
    data: [
        {
            question: "What is the most iconic sniper rifle in CS:GO?",
            options: ["AWP", "SCAR-20", "SSG 08", "G3SG1"],
            correctAnswer: "AWP"
        },
        {
            question: "Which map features the famous 'Double Doors' in mid?",
            options: ["Dust II", "Inferno", "Nuke", "Mirage"],
            correctAnswer: "Dust II"
        },
        {
            question: "What is the maximum amount of money a player can have in CS:GO?",
            options: ["$16000", "$10000", "$20000", "$25000"],
            correctAnswer: "$16000"
        },
        {
            question: "Which team is tasked with planting the bomb?",
            options: ["Terrorists", "Counter-Terrorists", "SWAT", "Rebels"],
            correctAnswer: "Terrorists"
        },
        {
            question: "What is the name of the defusal kit used by Counter-Terrorists?",
            options: ["Defuse Kit", "Bomb Kit", "Tactical Kit", "Disarm Kit"],
            correctAnswer: "Defuse Kit"
        },
        {
            question: "Which pistol is known for its high armor penetration and used frequently in eco rounds?",
            options: ["Desert Eagle", "P250", "Glock-18", "USP-S"],
            correctAnswer: "P250"
        },
        {
            question: "Which map has a callout location known as 'Banana'?",
            options: ["Inferno", "Overpass", "Train", "Cache"],
            correctAnswer: "Inferno"
        },
        {
            question: "Which in-game grenade is used to obscure vision?",
            options: ["Smoke Grenade", "Flashbang", "HE Grenade", "Molotov"],
            correctAnswer: "Smoke Grenade"
        },
        {
            question: "Which CS:GO operation introduced the map 'Overpass'?",
            options: ["Operation Breakout", "Operation Phoenix", "Operation Hydra", "Operation Wildfire"],
            correctAnswer: "Operation Breakout"
        },
        {
            question: "What is the rank symbol for the highest rank in CS:GO matchmaking?",
            options: ["Global Elite", "Supreme Master First Class", "Legendary Eagle", "Master Guardian"],
            correctAnswer: "Global Elite"
        },
        {
            question: "Which map in CS:GO features the callout 'Heaven'?",
            options: ["Nuke", "Mirage", "Dust II", "Inferno"],
            correctAnswer: "Nuke"
        },
        {
            question: "What is the cost of an AWP in CS:GO?",
            options: ["$4750", "$5000", "$5500", "$4200"],
            correctAnswer: "$4750"
        },
        {
            question: "Which weapon is known for its one-shot kill potential to the head without armor?",
            options: ["Desert Eagle", "AK-47", "M4A1-S", "P90"],
            correctAnswer: "AK-47"
        },
        {
            question: "Which grenade deals damage over time when thrown?",
            options: ["Molotov", "HE Grenade", "Smoke Grenade", "Flashbang"],
            correctAnswer: "Molotov"
        },
        {
            question: "What is the in-game term for when a team loses consecutive rounds and receives increased money?",
            options: ["Loss Bonus", "Eco Bonus", "Reset Bonus", "Round Bonus"],
            correctAnswer: "Loss Bonus"
        },
        {
            question: "Which rifle has both automatic and burst-fire modes?",
            options: ["FAMAS", "M4A4", "Galil AR", "AUG"],
            correctAnswer: "FAMAS"
        },
        {
            question: "Which map features a location known as 'Tetris'?",
            options: ["Mirage", "Inferno", "Train", "Overpass"],
            correctAnswer: "Mirage"
        },
        {
            question: "Which player role is often responsible for entering bomb sites first?",
            options: ["Entry Fragger", "AWPer", "Support", "In-Game Leader"],
            correctAnswer: "Entry Fragger"
        },
        {
            question: "Which pistol is the default starting weapon for the Terrorist side?",
            options: ["Glock-18", "USP-S", "P250", "CZ75-Auto"],
            correctAnswer: "Glock-18"
        },
        {
            question: "Which CS:GO map features a canal that runs through the middle?",
            options: ["Canals", "Inferno", "Vertigo", "Nuke"],
            correctAnswer: "Canals"
        },
        {
            question: "Which weapon is known as the 'Green Gun' in CS:GO?",
            options: ["AWP", "M4A1-S", "UMP-45", "SSG 08"],
            correctAnswer: "AWP"
        },
        {
            question: "Which map has the bombsite callout 'Pit'?",
            options: ["Inferno", "Dust II", "Mirage", "Train"],
            correctAnswer: "Inferno"
        },
        {
            question: "What is the default amount of rounds required to win a competitive match in CS:GO?",
            options: ["16", "12", "20", "18"],
            correctAnswer: "16"
        },
        {
            question: "Which knife skin is the rarest in CS:GO?",
            options: ["Karambit Fade", "M9 Bayonet Doppler", "Butterfly Knife Tiger Tooth", "Shadow Daggers Safari Mesh"],
            correctAnswer: "Karambit Fade"
        },
        {
            question: "What is the nickname for the AUG rifle in CS:GO?",
            options: ["Bullpup", "Scout", "Krieg", "Silent Killer"],
            correctAnswer: "Bullpup"
        },
        {
            question: "Which grenade in CS:GO can reduce movement speed when hit by it?",
            options: ["HE Grenade", "Molotov", "Flashbang", "Smoke Grenade"],
            correctAnswer: "Molotov"
        },
        {
            question: "Which weapon has a faster rate of fire, the MP9 or the P90?",
            options: ["P90", "MP9", "They have the same rate", "UMP-45"],
            correctAnswer: "P90"
        },
        {
            question: "Which CS:GO map is known for having the 'A Long' callout?",
            options: ["Dust II", "Overpass", "Inferno", "Mirage"],
            correctAnswer: "Dust II"
        },
        {
            question: "Which in-game character assists the Counter-Terrorists by giving mission updates?",
            options: ["Overwatch Commander", "Commanding Officer", "Dispatcher", "Radio Guy"],
            correctAnswer: "Overwatch Commander"
        },
        {
            question: "What is the name of the aggressive tactic used by CTs to gain early map control?",
            options: ["Pushing", "Stacking", "Rotating", "Holding Angles"],
            correctAnswer: "Pushing"
        }

    ]
};


export const re4Quiz = {
    quizPath: 'residentevil4',
    quizHeader: "Think you know everything about Resident Evil 4? Test your knowledge about Leon's journey, iconic enemies, and memorable moments in this survival horror classic!",
    image: "/images/quizzes/resident-evil-4.jpeg",
    data: [
        {
            question: "Who is the main protagonist in Resident Evil 4?",
            options: ["Leon S. Kennedy", "Chris Redfield", "Jill Valentine", "Claire Redfield"],
            correctAnswer: "Leon S. Kennedy"
        },
        {
            question: "Who is Leon tasked with rescuing in Resident Evil 4?",
            options: ["Ashley Graham", "Ada Wong", "Sherry Birkin", "Claire Redfield"],
            correctAnswer: "Ashley Graham"
        },
        {
            question: "What is the name of the cult that Leon faces throughout Resident Evil 4?",
            options: ["Los Illuminados", "Umbrella Corporation", "The Hive", "The Followers"],
            correctAnswer: "Los Illuminados"
        },
        {
            question: "Which character is known for assisting Leon while dressed in a red dress?",
            options: ["Ada Wong", "Ashley Graham", "Ingrid Hunnigan", "Jill Valentine"],
            correctAnswer: "Ada Wong"
        },
        {
            question: "What is the name of the village chief that Leon fights early in the game?",
            options: ["Bitores Mendez", "Ramon Salazar", "Jack Krauser", "Albert Wesker"],
            correctAnswer: "Bitores Mendez"
        },
        {
            question: "Which weapon is famous for being a one-hit kill against almost any enemy?",
            options: ["Rocket Launcher", "Chicago Typewriter", "Broken Butterfly", "Shotgun"],
            correctAnswer: "Rocket Launcher"
        },
        {
            question: "What is the name of the parasite that infects the enemies in Resident Evil 4?",
            options: ["Las Plagas", "T-Virus", "G-Virus", "Uroboros"],
            correctAnswer: "Las Plagas"
        },
        {
            question: "Who is the antagonist that Leon fights in a knife duel?",
            options: ["Jack Krauser", "Albert Wesker", "Bitores Mendez", "Luis Sera"],
            correctAnswer: "Jack Krauser"
        },
        {
            question: "What item does the Merchant often say when you first interact with him?",
            options: ["What're ya buyin'?", "Welcome, stranger!", "Need something, friend?", "Care to trade?"],
            correctAnswer: "What're ya buyin'?"
        },
        {
            question: "What is the name of the castle that Leon and Ashley explore?",
            options: ["Salazar Castle", "Mendez Manor", "Spencer Mansion", "Umbrella Fortress"],
            correctAnswer: "Salazar Castle"
        }
    ]
};

export const fortniteQuiz = {
    quizPath: 'fortnite',
    quizTitle: "Fortnite",
    quizHeader: "Think you know everything about Fortnite? Test your knowledge about weapons, seasons, and gameplay mechanics of this battle royale sensation!",
    image: "/images/quizzes/fortnite.png",
    data: [
        {
            question: "What is the name of the in-game currency used in Fortnite?",
            options: ["V-Bucks", "Coins", "FortCoins", "Battle Stars"],
            correctAnswer: "V-Bucks"
        },
        {
            question: "Which item allows you to jump high and avoid fall damage?",
            options: ["Shockwave Grenade", "Chug Jug", "Port-a-Fort", "Boogie Bomb"],
            correctAnswer: "Shockwave Grenade"
        },
        {
            question: "Which of these locations is not a named place in Fortnite?",
            options: ["Loot Lake", "Salty Springs", "Dusty Depot", "Misty Meadow"],
            correctAnswer: "Misty Meadow"
        },
        {
            question: "Who developed Fortnite?",
            options: ["Epic Games", "Activision", "Ubisoft", "EA Sports"],
            correctAnswer: "Epic Games"
        },
        {
            question: "What does the 'Victory Royale' mean?",
            options: ["Winning a match", "Opening a chest", "Finding a legendary weapon", "Reviving a teammate"],
            correctAnswer: "Winning a match"
        },
        {
            question: "What is the maximum number of players in a standard Fortnite Battle Royale match?",
            options: ["100", "50", "75", "150"],
            correctAnswer: "100"
        },
        {
            question: "What is the name of Fortnite's co-op PvE mode?",
            options: ["Save the World", "Survive the Storm", "Battle Royale", "Fortnite Frenzy"],
            correctAnswer: "Save the World"
        },
        {
            question: "Which weapon was removed from Fortnite in early seasons for being overpowered?",
            options: ["Infinity Blade", "Boom Bow", "Crossbow", "Tactical SMG"],
            correctAnswer: "Infinity Blade"
        },
        {
            question: "What is the purpose of a 'Launch Pad'?",
            options: ["To launch players into the air", "To heal teammates", "To create a shield", "To find loot"],
            correctAnswer: "To launch players into the air"
        },
        {
            question: "Which season introduced the iconic character 'Peely'?",
            options: ["Season 8", "Season 5", "Season 3", "Season 10"],
            correctAnswer: "Season 8"
        },
        {
            question: "What are 'Chug Splashes' used for?",
            options: ["To heal both health and shield", "To teleport", "To deal damage", "To increase speed"],
            correctAnswer: "To heal both health and shield"
        },
        {
            question: "Which company collaborated with Fortnite for a special Thanos event?",
            options: ["Marvel", "DC Comics", "Disney", "Star Wars"],
            correctAnswer: "Marvel"
        },
        {
            question: "Which item allows you to disguise as a prop?",
            options: ["Prop-O-Matic", "Chameleon Cloak", "Disguise Box", "Shadow Bomb"],
            correctAnswer: "Prop-O-Matic"
        },
        {
            question: "What happens when you eat a 'Slurp Mushroom'?",
            options: ["Gain shield", "Increase speed", "Jump higher", "Become invisible"],
            correctAnswer: "Gain shield"
        },
        {
            question: "Which of the following is a sniper rifle in Fortnite?",
            options: ["Heavy Sniper", "Burst AR", "Drum Gun", "Combat Shotgun"],
            correctAnswer: "Heavy Sniper"
        },
        {
            question: "Which character from the Star Wars franchise was added to Fortnite as a skin?",
            options: ["Darth Vader", "Obi-Wan Kenobi", "Luke Skywalker", "Han Solo"],
            correctAnswer: "Darth Vader"
        },
        {
            question: "What is the name of the storm phase that players must avoid?",
            options: ["The Circle", "The Zone", "The Danger", "The Eye"],
            correctAnswer: "The Zone"
        },
        {
            question: "What type of vehicle was introduced in Fortnite's Chapter 2?",
            options: ["Motorboats", "Planes", "Helicopters", "Golf Carts"],
            correctAnswer: "Motorboats"
        },
        {
            question: "Which event caused the original Fortnite map to change dramatically?",
            options: ["The Black Hole", "The Storm Surge", "The Cube", "The Great Flood"],
            correctAnswer: "The Black Hole"
        },
        {
            question: "Which of these is a type of building material in Fortnite?",
            options: ["Wood", "Steel", "Brick", "Iron"],
            correctAnswer: "Wood"
        },
        {
            question: "Which POI was known for its central clock tower?",
            options: ["Tilted Towers", "Salty Springs", "Lazy Lake", "Retail Row"],
            correctAnswer: "Tilted Towers"
        },
        {
            question: "What does the 'Storm Surge' do in Fortnite?",
            options: ["Damages players during passive play", "Speeds up the storm", "Heals all players", "Spawns loot"],
            correctAnswer: "Damages players during passive play"
        },
        {
            question: "What is the name of the landmark that houses the Zero Point?",
            options: ["The Spire", "The Agency", "The Grotto", "The Rig"],
            correctAnswer: "The Spire"
        },
        {
            question: "Which in-game item allows players to redeploy their glider at any time?",
            options: ["Glider Redeploy", "Shockwave Bow", "Launch Pad", "Grappler"],
            correctAnswer: "Glider Redeploy"
        },
        {
            question: "Which Fortnite character was part of the Season 5 Bounty Hunters storyline?",
            options: ["Mandalorian", "Deadpool", "Harley Quinn", "Batman"],
            correctAnswer: "Mandalorian"
        },
        {
            question: "Which consumable item increases health over time?",
            options: ["Slurp Juice", "Chug Jug", "Bandage Bazooka", "Mini Shield"],
            correctAnswer: "Slurp Juice"
        },
        {
            question: "What is the name of the item that allows players to respawn their teammates?",
            options: ["Reboot Van", "Respawn Beacon", "Recovery Truck", "Revive Bus"],
            correctAnswer: "Reboot Van"
        },
        {
            question: "Which Fortnite season was known as 'Primal'?",
            options: ["Chapter 2, Season 6", "Chapter 2, Season 3", "Chapter 1, Season 8", "Chapter 3, Season 1"],
            correctAnswer: "Chapter 2, Season 6"
        },
        {
            question: "What is the maximum number of materials a player can carry for each resource?",
            options: ["999", "500", "1500", "2000"],
            correctAnswer: "999"
        },
        {
            question: "Which DC character had a crossover event with Fortnite in Chapter 2, Season 6?",
            options: ["Batman", "Wonder Woman", "Superman", "The Flash"],
            correctAnswer: "Batman"
        },
        {
            question: "Which skin is known for being a symbol of rare, early Fortnite players?",
            options: ["Renegade Raider", "Jonesy", "Fishstick", "Peely"],
            correctAnswer: "Renegade Raider"
        },
        {
            question: "What is the name of the vehicle that was introduced in Chapter 1, Season 4?",
            options: ["Shopping Cart", "Baller", "Quadcrasher", "Golf Cart"],
            correctAnswer: "Shopping Cart"
        },
        {
            question: "What is the name of the Fortnite island map introduced in Chapter 3?",
            options: ["The Flipside", "The Loop", "Neo Tilted", "The Spire"],
            correctAnswer: "The Flipside"
        },
        {
            question: "Which famous music artist held a live concert event in Fortnite in 2020?",
            options: ["Travis Scott", "Drake", "Ariana Grande", "Marshmello"],
            correctAnswer: "Travis Scott"
        },
        {
            question: "What is the maximum amount of shield a player can have in Fortnite?",
            options: ["100", "150", "200", "50"],
            correctAnswer: "100"
        },
        {
            question: "Which Marvel superhero was the secret skin in Chapter 2, Season 4?",
            options: ["Wolverine", "Iron Man", "Captain America", "Thor"],
            correctAnswer: "Wolverine"
        },
    ]
};

// Export other quiz data as needed
