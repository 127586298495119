import React, { useEffect } from "react";

const AdCashAntiAdblock = () => {
  useEffect(() => {
    
    const fetchLibrary = async () => {
      try {
        const response = await fetch("https://us-central1-dissonant-druid.cloudfunctions.net/getAdCashLibrary");
        const libraryCode = await response.text();
    
        // Ensure the response is valid JavaScript
        if (libraryCode.trim().startsWith("<")) {
          throw new Error("Invalid JavaScript response: Received HTML or malformed content.");
        }
    
        // Inject the AdCash script into the DOM
        const script = document.createElement("script");
        script.type = "text/javascript";
        script.text = libraryCode;
        document.head.appendChild(script);
    
        // Initialize the AdCash AutoTag
        script.onload = () => {
          window.aclib && window.aclib.runAutoTag({
            zoneId: "emtvoq8s5y", // Replace with your actual Zone ID
          });
        };
      } catch (error) {
        console.error("Failed to load AdCash library:", error);
      }
    };
    

    fetchLibrary();
  }, []);

  return null; // No UI elements required for the ad library
};

export default AdCashAntiAdblock;
